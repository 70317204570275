@import '../configuration/config';

.equote__package-details-outline {
  margin-bottom: 20px !important;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 0 20px;

  @include MQ(md) {
    padding: 20px 64px;
  }

  @include MQ(lg) {
    padding: 0 20px;
  }

  &--branding {
    display: flex;
  }
  &--title {
    display: flex;
    align-items: center;
    padding: 30px 0 0 0;
    flex-direction: column;
    flex: 1 1 65%;
    order: 1;
    width: 100%;

    @include MQ(xs) {
      width: auto;
    }

    @include MQ(md) {
      flex-direction: row;
    }

    @include MQ(lg) {
      padding: 30px 0;
    }
    p {
      font-size: 28px !important;
      text-align: center;

      @include MQ(md) {
        font-size: 32px;
        margin: 0;
        margin-left: 50px;
      }
    }
    &-logo {
      width: 100%;
      // height: 100%;
      display: block;
      margin: 0 auto;

      @include MQ(xs) {
        width: 245px;
      }

      @include MQ(lg) {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }
  }

  &-fees {
    order: 4;
    width: 100%;

    p {
      font-size: 0.7em !important;
      margin-top: 10px;
      text-align: center;

      @include MQ(md) {
        text-align: right;
      }
    }
  }

  &-print-button {
    order: 1;
    width: 100%;
    margin: 15px 0;

    @include MQ(lg) {
      order: 2;
      width: auto;
      margin: 40px 0 0 0;
      padding: 0 15px;
    }

    @include OnIE() {
      margin: 32px 0;
    }

    .btn {
      cursor: pointer;
      display: none;
      border: 1px solid $black;
      margin: 0 auto;
      text-transform: uppercase;
      text-align: center;
      padding: 7px 20px;
      font-weight: 400;
      font-size: 10px;
      background-color: $black;
      color: $white;

      @include OnIE() {
        padding: 1px 12px !important;
      }

      @include MQ(md) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        max-height: 30px;
        max-width: 17px;
        margin-right: 10px;
      }
    }
  }

  &-share {
    display: block;
    -webkit-margin-after: 2em;
    margin-block-end: 2em;
    align-content: flex-end;
    margin: 30px auto;
    order: 4;

    @include MQ(lg) {
      order: 3;
    }

    p {
      font-size: 15px;
      justify-content: center;
      display: flex;
      margin: 0;
      margin-bottom: 3px;
    }
    &-icons {
      justify-content: center;
      display: flex;
      height: 30px;

      img {
        width: 27px;
        transition: 0.2s all ease-in-out;
        margin-right: 3px;

        @include OnIE() {
          height: 27px;
        }
      }
    }
  }

  &-tour-details {
    order: 2;
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
    @include MQ(lg) {
      order: 3;
    }

    &--primary-title {
      margin: 0;
      padding: 0 30px;
      font-size: 28px !important;
      font-weight: 900 !important;
      letter-spacing: 0.05em;
      line-height: 1.5;
      @include MQ(sm) {
        line-height: 2.1 !important;
      }
      border: 1px solid #c4c4c4;
    }
    &--pre-cruise {
      border: 1px solid #c4c4c4;
      border-bottom: none;
    }
    &--post-cruise {
      border: 1px solid #c4c4c4;
      border-top: none;
      border-bottom: none;
    }
    &--pre-cruise,
    &--post-cruise {
      p {
        font-size: 28px !important;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        margin: 0;
        color: $dark-grey;
        font-weight: 900 !important;
        padding-right: 8px;
        line-height: 1.5 !important;

        @include MQ(sm) {
          line-height: 2.1 !important;
        }
      }
    }

    &--post-cruise,
    &--pre-cruise {
      display: flex;
      &-flag,
      &-flag {
        background-color: $royal-bright;
        padding: 0 10px 0 10px;
        position: relative;
        overflow: hidden;

        @include MQ(sm) {
          padding: 0 45px 0 20px;
        }

        p {
          margin: 0;
          color: white !important;
          font-size: 16px !important;
          padding: 10px 10px 10px 0;
          height: 100%;

          @include MQ(sm) {
            padding: 10px;
          }
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -33px;
          height: 120px;
          width: 40px;
          background: $white;

          @include MQ(sm) {
            right: -13px;
          }
        }

        &:before {
          top: -24px;
          transform: rotate(-20deg);
        }

        &:after {
          transform: rotate(20deg);
          bottom: -24px;
        }
        @at-root {
          .celebrity {
            .equote-preview-package-outline-tour-details--pre-cruise-flag,
            .equote-preview-package-outline-tour-details--post-cruise-flag {
              background-color: $celebrity-bright;
            }
          }
          .royal {
            .equote-preview-package-outline-tour-details--pre-cruise-flag,
            .equote-preview-package-outline-tour-details--post-cruise-flag {
              background-color: $royal-bright;
            }
          }
        }
      }
    }
  }

  @at-root {
    .royal {
      .equote-preview-package-outline--title {
        p {
          color: #585858;
        }
      }
    }
    .celebrity {
      .equote-preview-package-outline--title {
        p {
          font-weight: $celebrity-font-weight-light;
          font-size: 30px;
          line-height: 32px;
          letter-spacing: 0.05em;
          color: $brand-base-celebrity;
          padding: 0 20px;

          @include MQ(md) {
            padding: 0;
            font-size: 40px;
            line-height: 82px;
          }
        }
        &-share p {
          font-size: 15px;
          line-height: 32px;
          font-weight: normal;
          color: $celebrity-grey;
        }
      }
    }
  }

  &--table {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    order: 2;
    width: 100%;
    @include MQ(md) {
      margin: 0;
      flex-direction: row;
    }
    @include MQ(lg) {
      order: 4;
    }

    @include MQ(lg) {
      display: flex;
      flex-direction: row;
      //flex-wrap: nowrap;
    }

    &-cell {
      //width: 50%;
      border: 1px solid #c4c4c4;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      @include MQ(lg) {
        //width: auto;

        &:not(:last-child) {
          border-right: none;
        }
      }

      &--heading,
      &--info {
        letter-spacing: 0.05em;
        padding: 0 15px;
        font-size: 14px;

        @include MQ(md) {
          padding-left: 20px;
          font-size: 15px;
        }

        &.pricing-info-cell {
          padding: 0;
        }
      }
      &--heading {
        font-size: 14px !important;
        line-height: 20px;
        text-transform: capitalize;
        color: white !important;
        margin: 0;
        padding: 5px 8px;
        letter-spacing: 0.05em;
        background-color: $royal-blue;
        min-height: 60px;
        flex: 1 0 auto;
        @include MQ(xs) {
          min-height: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @include MQ(sm) {
          padding: 10px 15px;
        }

        @include MQ(md) {
          font-size: 15px !important;
          padding: 10px 20px;
        }
      }
      &--info {
        line-height: 20px;
        display: flex;
        align-items: center;
        height: 100%;

        @include MQ(md) {
          line-height: 25px;
        }

        .equote__package-details-outline--table-cell--info--pricing {
          display: flex;
          width: 100%;
          flex-direction: column;
          .equote__package-details-outline--table-cell--info--pricing-cell {
            font-size: 14px;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            line-height: 1.1;
            padding: 12px 16px;
            &:not(:first-child) {
              border-top: 1px solid #c4c4c4;
            }
            @include MQ(md) {
              &:not(:first-child) {
                border-top: 0;
                border-left: 1px solid #c4c4c4;
              }
            }
            &--guest-count {
            }
            &--price {
              font-weight: 700;
              padding: 4px 0;
            }
            &--promo,
            &--obc {
              font-size: 12px;
              color: #888;
              line-height: 1.1;

              font-weight: 400;

              @include MQ(md) {
                padding: 0;
              }
            }
          }
          @include MQ(md) {
            flex-direction: row;
            min-height: 64px;
          }
        }
      }

      @at-root .celebrity {
        .equote__package-details-outline-print-button {
          .btn {
            background-color: #000 !important;
          }
        }
        .equote__package-details-outline--table-cell--heading {
          background-color: #000 !important;
          color: white;
        }
      }

      @at-root .royal {
        .equote__package-details-outline-print-button {
          .btn {
            background-color: $royal-blue !important;
          }
        }
        .equote__package-details-outline--table-cell--heading {
          background-color: $royal-blue !important;
          color: white;
        }
      }
    }
  }
}
