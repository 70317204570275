@import '../configuration/config';
.equote__package-details-state-rooms {
  overflow: hidden;
  width: 100%;

  .equote__package-details-state-rooms-slider {
    width: 100%;

    @include MQ(xlr) {
      display: none;
    }

    .equote__package-details-state-room {
      &--sub-table--row--item {
        padding-left: 15px;
        padding-top: 15px;
        display: flex;
        align-items: center;

        @include MQ(lg) {
          padding-left: 24px;
        }
      }

      &--sub-table--row--item p {
        line-height: 16px;
        font-weight: 900;

        @include MQ(lg) {
          line-height: 26px;
        }
      }
      &--sub-table--row--item-promotion-info {
        font-weight: 300;
        padding-top: 10px;
        display: block;

        @include MQ(lg) {
          display: inline;
        }
      }

      &--sub-table--row {
        background-color: #fff;
        @include MQ(xlr) {
          &:nth-child(odd) {
            background-color: $grey;
          }
        }
      }
    }
    .slick-slider {
      //overflow: hidden;
      padding: 0;
    }
    .slick-list {
      overflow: visible;
      width: 100%;

      @include MQ(md) {
        margin-left: 36px;
      }
    }
    .slick-track {
      @include MQ(md) {
        display: flex;
      }
    }
    .slick-prev {
      left: -16px;
      &:before {
        display: none;
      }
    }
    .slick-next {
      right: -16px;
      &:before {
        display: none;
      }
    }
    .slick-slide {
      z-index: 98;
      &.slick-active {
        z-index: 99;
        .equote__package-details-state-room--body {
          margin: auto;
        }
      }
      @include MQ(md) {
        padding: 0 41px 0 28px;
      }

      &.slick-current {
        @include MQ(md) {
          padding-right: 41px;
          margin-right: -41px;
        }
      }
      height: auto;
      transition: all 0.2s ease;

      &:not(.slick-active) {
        opacity: 0;
        @include MQ(md) {
          opacity: 0.5;
          transform: scale(0.9) translateX(-90px);
          display: flex;
        }
      }
      > div {
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }
}
