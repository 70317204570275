@import '../configuration/config';

body.drawer-open {
  overflow: hidden;
}

.equote__agent-details-drawer {
  background-color: $white;
  bottom: 0;
  left: 350px * -1 - 64;
  max-width: 350px;
  overflow-y: auto;
  padding: $col-gutter $col-gutter * 2 $col-gutter - 10 $col-gutter * 2;
  position: fixed;
  top: 201px;
  transition: all 0.25s ease;
  width: 100%;
  z-index: 21;

  @media screen and ($bp-min-lg) {
    top: 101px;
  }

  &.active {
    left: 0;
    box-shadow: 1px 10px 18px rgba(0, 0, 0, 0.5);
  }

  .equote__agent-details-drawer-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;

    .u-modal__close {
      transform: unset;
    }
  }

  .equote__agent-details-drawer-data-image {
    text-align: center;

    img {
      width: auto;
      max-width: 100%;
    }
  }

  .equote__agent-details-drawer-data-col {
    border-bottom: $border-grey;
    padding: $grid-unit * 2 $grid-unit;

    &:last-of-type {
      border: none;
    }

    .title {
      font-weight: 400;
    }

    .details {
      color: $tri-brand-blue-one;
    }
  }
}