@import '../configuration/config';

.slick-slider {
  .slick-arrow {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #15264c;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    &.hide {
      display: none;
    }
    &.large {
      width: 50px;
      height: 50px;
      svg {
        width: 30px;
        height: 30px;

        path {
          stroke: $white;
        }
      }
    }
    &:before {
      content: '';
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .slick-dots {
    li {
      width: 30px;
      height: 30px;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 4px;
        background-color: transparent;
        border: 1px solid #c4c4c4;
        &:before {
          content: '';
        }
      }

      &.slick-active {
        button {
          background-color: #c4c4c4;
        }
      }
    }
  }
}
@at-root {
  .celebrity {
    .slick-slider {
      .slick-arrow {
        background-color: $celebrity-blue;
      }
    }
  }
  .royal {
    .slick-slider {
      .slick-arrow {
        background-color: $royal-blue;
      }
    }
  }
}
