@import '../configuration/config';

.equote__sailings-full-search {
  padding: 0 0 $col-gutter + 10 0;

  .equote__sailings-full-search-filters {
    background-color: $light-grey;
    padding: $col-gutter * 2;
    text-align: center;
  }

  .equote__sailings-full-search-table {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;

    @include MQ(xl) {
      padding: 0 30px;
    }

    @include MQ(xxl) {
      padding: 0;
    }

    .title {
      width: 100%;
      margin: 16px 0 24px;
    }

    table {
      position: relative;
    }

    .btn-text {
      font-size: $font-size-base - 4;
      padding: 0;
      text-align: left;
      flex-wrap: nowrap;
    }

    &-wrapper {
      order: 3;
      width: 100%;
      display: flex;
      align-items: flex-start;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 30px;
        z-index: 10;
        border-right: 1px solid #e5e5e5;
        margin-bottom: 87px;
        @include MQ(lgr) {
          display: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 30px;
        z-index: 10;
        border-left: 1px solid #e5e5e5;
        margin-bottom: 87px;

        @include MQ(lgr) {
          display: none;
        }
      }

      &.show-fade-right {
        &::after {
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
        }
      }

      &.show-fade-left {
        &::before {
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
        }
      }

      .scroll-left,
      .scroll-right {
        position: sticky;
        top: 0;

        img {
          width: 25px;
        }

        @include MQ(lgr) {
          display: none;
        }
      }

      .scroll-left {
        order: 1;
        left: 0;
        transform: translate(-90%, 85%);
        padding: 0;
      }

      .scroll-right {
        order: 3;
        right: 0;
        transform: translate(-75%, 85%);
        padding: 0;

        @include OnIE() {
          transform: translate(110%, 85%);
        }
      }

      .equote__sailings-cruises-table,
      .equote__sailings-cruise-tours-table,
      .equote__sailings-land-programs-table {
        order: 2;
        margin-left: -25px;
        margin-right: 25px;

        @include OnIE() {
          margin-right: -25px;
        }

        @include MQ(lgr) {
          margin: 0;
        }
      }
    }
  }

  .equote__sailings-cruises-table,
  .equote__sailings-cruise-tours-table,
  .equote__sailings-land-programs-table {
    margin: 0 0 $col-gutter + 10 0;
    width: 100%;

    tfoot {
      @include OnIE() {
        div span:nth-of-type(3) {
          line-height: 0;
          min-width: 80px;
        }
      }
    }

    th {
      &:after {
        content: '';
        background-color: $grey;
        width: 1.5px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -1.5px;
      }
    }

    div[style^='overflow'] {
      @include MQ(lgr) {
        overflow: unset !important;
      }
    }
  }

  .equote__sailings-full-search--table-body-row {
    td:first-child {
      // this is a solution to hide the buttons necessary to toggle our sub tables
      width: 1px !important;
      position: fixed;
      left: -10px;
      top: -10px;
    }

    &.has-active-cell {
      td {
        .equote__sailings-table-best-price-taxes.absolute {
          display: none;
        }
      }
    }
  }
  .equote__sailings-full-search-cabins-table {
    background-color: $white;
    padding: $col-gutter * 2 + 10;
    border-top: 2px solid $medium-grey;
    border-right: 1px solid $medium-grey;
    border-left: 1px solid $medium-grey;
    border-bottom: 1px solid $medium-grey;

    &.no-border-top {
      border-top: none;
    }

    h5 {
      text-transform: uppercase;
    }

    tr {
      display: table-row !important;

      &:nth-child(even) {
        background-color: $lighter-grey;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &-button {
        display: flex;
        cursor: pointer;
        right: 0;
      }
    }

    &:not(:first-child) {
      border-top: none;
    }

    &:not(:last-child) {
      border-bottom: none;
    }
  }
  .u-table-price-cell {
    min-width: 132px;
  }
  .equote__sailings-table-best-price {
    display: inline-block;
    font-size: $font-size-base - 4;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }
  .equote__sailings-table-best-price-description {
    word-break: break-word;
    word-wrap: break-word;
  }
  .equote__sailings-table-best-price-taxes {
    font-size: 12px;
    font-style: italic;
    &.absolute {
      position: absolute;
      bottom: 0;
      margin: 0;
      white-space: nowrap;
      background: #fff;
      color: rgb(77, 77, 77);
      border-top: 1px solid rgb(229, 229, 229);
      width: 531px;
      text-align: center;
      z-index: 11;

      @at-root .secondary-background {
        .equote__sailings-table-best-price-taxes {
          &.absolute {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }

  .equote__sailings-table-best-price-description,
  .equote__sailings-table-best-price-credit {
    color: $medium-grey;
    display: inline-block;
    font-size: $font-size-base - 6;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  .u-font-sold-out {
    display: inline-block;
    font-size: $font-size-base - 5;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  &--table-body-row {
    button:not(.btn) {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      margin: 0;
    }
    .btn {
      padding: 0;
    }
  }

  .equote__sailings-full-search-table-tabs {
    order: 1;
    width: 100%;

    .equote__sailings-full-search-table-tab {
      margin: 0 4px;
    }

    &-details {
      background-color: initial;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      text-align: center;
      margin: auto;

      &:hover:not(:disabled) {
        text-decoration: underline;
      }
    }
  }

  .equote__sailings-full-search--land-packages-cabins-container {
    position: relative;
    border-top: 1px solid $medium-grey;

    &-heading {
      position: absolute;
      top: 28px;
      right: 50px;
      z-index: 2;

      &-button {
        transform: none;
      }
    }

    .equote__sailings-full-search-cabins-table {
      padding: 32px 50px;
    }
  }
}
