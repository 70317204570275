@import '../configuration/config';

.equote__package-details--land-programs {
  padding: 32px 32px 0 32px;

  .equote__package-details--land-programs--header {
    margin: 0 auto 32px;
    max-width: 1440px;
    width: 100%;
    padding: 0 32px;
    .equote__package-details--land-programs--header--title {
      font-size: 38px;
      text-transform: uppercase;
    }
    .equote__package-details--land-program {
      .equote__package-details--land-program--header--sub-title {
        display: flex;
        align-items: center;
        font-size: 22px;
        color: $royal-blue;
        margin: 0;
        .equote__package-details--land-program--header--pre-post-flag {
          position: relative;
          display: flex;
          margin-left: 16px;
          align-items: center;
          background-color: $tri-brand-blue-two;
          height: 34px;
          color: #fff;
          padding: 0 8px 0 20px;
          font-size: 15px;

          img {
            margin-left: 8px;
          }
          span {
            white-space: nowrap;
            text-transform: uppercase;
          }
          &:before {
            content: '';
            position: absolute;
            left: -3px;
            top: -10px;
            height: 31px;
            width: 12px;
            background-color: #fff;
            /* border-right: 12px solid #3f6bab; */
            /* border-top: 17px solid #fff; */
            /* border-bottom: 17px solid #fff; */
            transform: rotate(41deg);
          }
          &:after {
            content: '';
            position: absolute;
            left: -3px;
            bottom: -10px;
            height: 31px;
            width: 12px;
            background-color: #fff;
            /* border-right: 12px solid #3f6bab; */
            /* border-top: 17px solid #fff; */
            /* border-bottom: 17px solid #fff; */
            transform: rotate(-41deg);
          }
        }
      }
      .equote__package-details--land-program--header--date {
        font-size: 15px;
      }
    }
  }

  .equote__package-details--land-program--slider-container {
    padding: 0 64px;

    .slick-list {
      width: 95%;
      overflow: visible;

      .slick-slide:not(.slick-active) {
        opacity: 0.5;
        transform: scale(0.9);
      }
    }

    .equote__package-details--land-program--slide {
      padding: 0 15px;
      .equote__package-details--land-program--slide--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $tri-brand-blue-two;
        height: 50px;
        padding-left: 20px;
        p {
          margin: 0;
          color: #fff;
          font-weight: 700;
          font-size: 18px;
        }
        .equote__package-details--land-program--slide--header-image-container {
          background-color: #00aac6;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: -1px;
            width: 0;
            height: 0;
            border-right: 30px solid transparent;
            border-top: 50px solid $tri-brand-blue-two;
          }
        }
      }

      .equote__package-details--land-program--slide--body {
        display: flex;
        min-height: 275px;
        .equote__package-details--land-program--slide--img-container {
          width: 50%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .equote__package-details--land-program--slide--details {
          width: 50%;
          background-color: $light-grey;
          padding: 16px;
          p {
            margin: 0;
            line-height: 1.5;
            color: #636466;
            font-size: 16px;
          }
          .equote__package-details--land-program--slide--day {
            font-size: 25px;
          }

          button {
            appearance: none;
            background-color: transparent;
            border: none;
            color: $tri-brand-blue-one;
          }
        }
        img {
          display: none;
        }
      }
    }
  }

  @at-root {
    .royal {
      .equote__package-details--land-program {
        .equote__package-details--land-program--header {
          .equote__package-details--land-program--header--title {
          }

          .equote__package-details--land-program--header--sub-title {
            color: $royal-blue;

            .equote__package-details--land-program--header--pre-post-flag {
            }
          }
        }
      }
    }

    .celebrity {
      .equote__package-details--land-program {
        .equote__package-details--land-program--header {
          .equote__package-details--land-program--header--title {
          }

          .equote__package-details--land-program--header--sub-title {
            color: $celebrity-blue;

            .equote__package-details--land-program--header--pre-post-flag {
            }
          }
        }
      }
    }
  }
}
