@import '../configuration/config';

.equote__search-price-table {
  .u-table {
    td {
      border-bottom: none;
    }
  }

  &-disclaimer {
    padding: 1rem 3rem 1rem 4rem;

    h5 {
      color: $medium-dark-grey;
      margin: 0 0 $col-gutter - 10 0;
    }

    p {
      line-height: 1.6;
    }

    @include MQ(md) {
      padding: 2rem 6rem 2rem 8rem;
    }

    @include MQ(xxl) {
      padding: 2rem 16rem 2rem 16rem;
    }
  }

  .equote__sailings-search-price-table-wrapper {
    padding: 0 10px;
    position: relative;

    @include MQ(md) {
      padding: 0 80px;
      position: relative;
    }

    .btn {
      position: absolute;
      top: 0;
      padding: 16px 4px;
      &.scroll-left {
        left: 0;
        transform: translate(-105%, 0);

        @include OnIE() {
          transform: translate(-80%, 0);
        }
      }
      &.scroll-right {
        right: 0;
        transform: translate(10%, 0);
        @include OnIE() {
          transform: translate(80%, 0);
        }
      }
    }
  }
  #equote__sailings-search-price-table-container {
    position: relative;
    display: flex;

    button.btn {
      position: sticky;
      top: 0;
      max-height: 40px;
    }

    .scroll-left {
      display: none;
      @include MQ(sm) {
        display: block;
      }
      order: 1;
    }
    .scroll-right {
      display: none;
      @include MQ(sm) {
        display: block;
      }
      order: 3;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 1px;
      width: 30px;
      z-index: 10;
      border-right: 1px solid #e5e5e5;

      @include OnIE() {
        right: 1px;
      }
    }
    &.show-fade {
      &::after {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
      }
    }
  }

  .equote__sailings-search-price-table {
    position: relative;
    width: 100%;
    order: 2;
    margin-left: 0px;

    @include MQ(sm) {
      margin-left: -30px;
    }

    @include OnIE() {
      margin-left: 0;
    }
    .equote__search-price-table-fixed-destination-header {
      width: 120px !important;

      @include MQ(sm) {
        width: 200px !important;
      }
    }

    .equote__search-price-table-mock-destination-header,
    .equote__search-price-table-fixed-destination-header {
      th:first-child {
        min-width: 120px !important;
        max-width: 120px !important;

        @include MQ(sm) {
          min-width: 200px !important;
          max-width: 200px !important;
        }
      }
      position: absolute;
      left: 0;
      top: 0;
      z-index: 11;
      width: 100%;
      overflow-x: auto;
      height: 56px;
      background-color: #2a467b !important;
      text-align: center;
      color: #fff;
      border-right: 1px solid #fff;
      text-transform: uppercase;
      -ms-overflow-style: none; // IE 10+
      scrollbar-width: none; // Firefox

      &::-webkit-scrollbar {
        display: none; // Safari and Chrome
      }

      p {
        padding: 18px 1px 4px 0px;
        margin: 0;
        font-size: 0.75rem;

        cursor: pointer;

        @include MQ(sm) {
          padding: 15px 56px 4px 0px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .equote__search-price-table-fixed-destination-header {
      position: absolute;
      top: 0;
      left: 0;

      @include MQ(md) {
        width: 200px;
      }

      @include OnIE {
        display: none !important;
      }
    }

    .price-info-button {
      appearance: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 12px;
      max-height: 68px;

      span {
        font-family: $sans-serif-stack;
      }
      &--best-price {
        font-size: 14px;
        color: $tri-brand-blue-two;
        font-weight: 600;
        font-family: $sans-serif-stack;
        display: inline;

        &:hover {
          color: $black;
        }
      }
      &--best-price-value {
        text-decoration: underline;
        display: inline;
      }
      .equote__sailings-table-best-price-description {
        display: block;
        width: 80px;
        word-break: break-word;
        word-wrap: break-word;
      }
    }
  }
  #equote__search-price-table-container {
    td:first-child,
    th:first-child {
      min-width: 120px !important;
      max-width: 120px !important;

      @include MQ(sm) {
        min-width: 200px !important;
        max-width: 200px !important;
      }
    }
  }

  .destination-cell {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    &.secondary-background {
      // background-color: $light-grey;
    }

    .destination-info-button {
      appearance: none;
      border: none;
      background: transparent;
      cursor: pointer;
      height: 100%;
      width: 100%;
      font-size: 14px;
      text-decoration: underline;
      color: $tri-brand-blue-two;
      font-weight: 600;
    }
  }
  &--filters {
    padding: 32px;
    background-color: $light-grey;
    margin-bottom: 32px;

    .u-custom-select {
      @include OnIE() {
        display: block !important;
      }
    }
    &-row {
      width: 100%;
    }

    .u-content-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include MQ(md) {
        flex-direction: row;
      }

      .u-flex {
        flex-direction: column;
        width: 100%;

        @include MQ(md) {
          width: auto;
          flex-direction: row;
        }
      }
    }

    h4 {
      color: $medium-dark-grey;
      font-size: 17px;
      text-transform: uppercase;
    }
  }
}
