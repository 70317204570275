@import '../configuration/config';
@import '../configuration/variables';

.equote__package-details-container {
  padding: 40px 100px;
  position: relative;

  .u-modal__close {
    position: absolute;
    right: 20px;
    top: 40px;
    transform: none;
  }
}

.equote__package-details {
  max-width: 1920px;
  width: 100%;
  margin: auto;
  height: 100%;

  .u-table {
    border: none;

    table {
      border: 1px solid $grey;
    }
  }
}

.equote__package-details-table-container {
  margin-bottom: 30px;
}

.equote__package-details-table-header {
  width: 100%;
  text-align: center;

  h6 {
    color: $font-color-heading;
  }
}
