@import '../configuration/config';

.equote__generate-offers {
  //Structure
  .u-flex {
    box-sizing: border-box;
    display: flex;

    &--wrap {
      flex-wrap: wrap;
    }

    &--column {
      flex-direction: column;
    }

    &--row {
      flex-direction: row;
    }

    &--even {
      justify-content: space-evenly;
    }

    &--stretch {
      align-items: stretch;
    }

    &--grow {
      flex-basis: 0;
      flex-grow: 1;
    }

    &--align-start {
      align-items: flex-start;
    }

    &--align-center {
      align-items: center;
    }

    &--align-end {
      align-items: flex-end;
    }

    &--align-space-between {
      align-items: space-between;
    }

    &--justify-center {
      justify-content: center;
    }

    &--justify-start {
      justify-content: flex-start;
    }

    &--justify-end {
      justify-content: flex-end;
    }

    &--justify-space-between {
      justify-content: space-between;
    }
  }

  //Flex Cols
  .u-flex-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--start {
      justify-content: flex-start;
    }
  }

  .u-flex-col {
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .u-flex-col-auto {
    flex: 0 0 auto;
    max-width: none;
    padding: 0 $col-gutter;
    width: auto;
  }

  .u-inline-block {
    display: inline-block;
  }

  .u-block {
    display: block;
  }
}