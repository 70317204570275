@import '../configuration/config';

.equote__search-filters-form-date-range-picker {
  .u-modal {
    &__header {
      flex-direction: column-reverse;
      justify-content: flex-end;

      .u-modal__close {
        align-self: flex-end;
      }
      @media screen and ($bp-min-xl) {
        flex-direction: row;
        .u-modal__close {
          align-self: inherit;
        }
      }
    }
  }

  .u-modal__body {
    padding: 7px;

    @include MQ(xs) {
      padding: 14px;
    }

    @include MQ(md) {
      padding: 20px;
    }
  }

  .equote__daypicker-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &.equote__daypicker-cell--unavailable {
      background: $lighter-grey;
      color: $medium-grey;

      &:hover {
        background: $tri-brand-blue-two;
        border: 1px double $tri-brand-blue-two;
        color: $white;
      }
    }
  }

  .equote__daypicker-legend {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .equote__daypicker-legend-col {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 0 $col-gutter 0;
      width: 100%;

      &:last-of-type {
        margin: 0;
      }

      @media screen and ($bp-min-sm) {
        margin: 0 $col-gutter 0 0;
        width: auto;
      }

      &.legend-blackout {
        .legend-icon {
          background-color: $lighter-grey;
          color: $medium-grey;
        }
      }

      .legend-icon {
        align-items: center;
        border: 1px solid $grey;
        color: $tri-brand-blue-two;
        display: flex;
        flex-wrap: wrap;
        height: 44px;
        justify-content: center;
        margin: 0 $col-gutter - 10 0 0;
        width: 44px;
      }

      .legend-label {
        color: $medium-grey;
        font-size: $font-size-base - 2;
      }
    }
  }
}
