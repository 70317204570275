@import 'mixins';

// breakpoints
$bp-min-xs: 'min-width: 321px';
$bp-min-sm: 'min-width: 480px';
$bp-min-md: 'min-width: 768px';
$bp-min-lg: 'min-width: 1024px';
$bp-min-lgr: 'min-width: 1158px';
$bp-min-xl: 'min-width: 1180px';
$bp-min-xlr: 'min-width: 1405px';
$bp-min-xxl: 'min-width: 1920px';
$bp-max-xs: 'max-width: 320px';
$bp-max-sm: 'max-width: 480px';
$bp-max-md: 'max-width: 768px';
$bp-max-lg: 'max-width: 1024px';
$bp-max-xl: 'max-width: 1180px';
$bp-max-xxl: 'max-width: 1920px';

// trasitions
$transition-speed: 0.25s;

// default and brand colors
$black: #000;
$charcol: #222;
$extreme-lighter-grey: #f8f8f8;
$lighter-grey: #f2f2f2;
$light-grey: #f5f5f5;
$grey: #e5e5e5;
$medium-grey: #888888;
$medium-dark-grey: #585858;
$dark-grey: #4d4d4d;
$bd-grey: #c4c4c4;
$white: #fff;
$error-red: #cd0000;
$light-blue: #f1f7fb;
$anchor-hover: #243c60;

$brand-base-royal: #0073bb;
$brand-base-celebrity: #101010;
$brand-base-azamara: #0073bb;

$brand-azamara-orange: #e55c00;
$brand-azamara-blue: #00205b;
$brand-celebrity-blue: #004275;
$brand-royal-gold: #f2c94c;

$tri-brand-blue-one: #2a467b;
$tri-brand-blue-two: #3f6bab;
$tri-brand-blue-three: #435382;
$tri-brand-blue-four: #324e5f;

$tri-brand-blue-hover: #35a1ee;

$font-color-base: #4d4d4d;
$font-color-heading: $tri-brand-blue-one;
$font-color-strikeout: #828282;

$sold-out-text: #a40c0c;

// layouts variables
$app-max-width: 1920px;
$content-min-width: 720px;
$content-max-width: 1440px;
$content-max-width: 1440px;
$content-max-width-alt: 1080px;
$content-max-width-outline: 1180px;

// typography variables
$sans-serif-stack: 'Lato';
$sans-serif-stack-light: 'Lato-Light';
$font-kapra: 'Kapra';
$font-proxima-nova: 'ProximaNova';
$font-roboto: 'Roboto';
$font-roboto-light: 'RobotoLight';

$font-size-base: 18px;
$font-size-small: $font-size-base - 3;
$font-base: 400 18px/18px $sans-serif-stack;
$font-weight-base: 400;
$font-weight-heading: $font-weight-base;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 700;
$font-weight-bold: 900;

$line-height-copy: 2.1;
$line-height-heading: 1;

$letter-spacing: 0.6px;

// margin and padding
$col-gutter: 20px;
$mb-base: 25px;
$grid-unit: 8px;

// borders and shadows
$border-light-grey: $grey 1px solid;
$border-light: $tri-brand-blue-one 1px solid;
$border-medium: $medium-grey 1px solid;
$border-grey: $grey 1px solid;
$border-main: $light-grey 1px solid;
$box-shadow-default: 0px 2px 2px rgba(0, 0, 0, 0.1);

// buttons
$button-primary-bg: #000;
$button-primary-hover-bg: lighten(#000, 45%);
$button-letter-spacing: 0;

//Brands

$brand-base-royal: #0073bb;
$brand-base-celebrity: #101010;
$brand-base-azamara-body: #636466;
$brand-base-azamara: #0073bb;

$royal-highlight: #f2c94c;
$royal-highlight-second: #255eac;
$royal-blue: #15264c;
$royal-bright: #005edc;
$royal-blue-banner: #1a7492;

$celebrity-blue: #004275;
$celebrity-bright: #2977aa;
$celebrity-grey: #171616;
$celebrity-highlight-second: #255eac;

$azamara-blue: #00205b;
$azamara-bright: #336699;
$azamara-orange: #e55c00;
$azamara-highlight-second: #255eac;
$azamara-green-banner: #ebeff1;

// typography variables
$sans-serif-stack: 'Lato', Arial, Helvetica, sans-serif;
$sans-serif-stack-light: 'Lato-Light', Arial, Helvetica, sans-serif;
$sans-serif-stack-bold: 'Lato-Bold', Arial, Helvetica, sans-serif;

// typography variables
$sans-serif-stack: 'Lato', Arial, Helvetica, sans-serif;
$sans-serif-stack-light: 'Lato-Light', Arial, Helvetica, sans-serif;
$sans-serif-stack-bold: 'Lato-Bold', Arial, Helvetica, sans-serif;

// royal stack
$royal-header-font: 'Kapra', 'Arial Black', Gadget, sans-serif;
$royal-primary-font: 'ProximaNova', Arial, Helvetica, sans-serif;
$royal-primary-font-weight: 400;
$royal-primary-font-weight-bold: 500;

// azamara stack
$azamara-header-font: 'Arial Black', Gadget, sans-serif;
$azamara-primary-font: 'Biotif-Regular', 'Lato', Arial, Helvetica, sans-serif;
$azamara-base-font-weight: 400;
$azamara-base-font-weight-bold: 700;

// celebrity stack
$celebrity-header-font: 'Roboto';
$celebrity-primary-font: 'Roboto';
$celebrity-font-weight-light: 300;
$celebrity-font-weight-regular: 400;
$celebrity-font-weight-medium: 600;
$celebrity-font-weight-bold: 700;
