@import '../configuration/config';

.equote-package-related-sailings-container {
  .equote-package-related-sailings-table {
    display: flex;
    padding: 0 24px;

    .featured-image {
      width: 25%;
      max-width: 356px;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    table.u-table {
      border-top: none;
      border-left: none;
      thead {
        th {
          font-family: $sans-serif-stack-light;
          padding: 15px 12px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.05em;
          &:first-child {
            padding: 15px 24px;
          }
        }
      }

      tr {
        td {
          font-size: 11px;
          padding: 40px 10px 18px 8px;
          vertical-align: top;

          &:first-child {
            padding: 15px 24px;
            color: $medium-grey;
            font-size: 14px;
            div {
              margin-bottom: 8px;
            }
          }
          &:last-child {
            padding: 18px 14px;
            vertical-align: middle;
          }
        }

        &:nth-child(odd) {
          background-color: $light-grey;
        }

        &:nth-child(even) {
        }
      }

      .table-cell-text--title {
        font-size: 21px;
        line-height: 23px;
        font-weight: 600;
        color: $medium-dark-grey;
        margin-bottom: 8px;
        text-transform: capitalize;
      }

      .table-cell-text--price {
        font-size: 17px;
        color: #000;
        margin-bottom: 8px;
      }

      .table-cell-text--promo,
      .table-cell-text--refundable {
        color: $medium-grey;
        white-space: nowrap;
      }

      .sold-out {
        font-size: 15px;
        text-align: center;
        text-transform: uppercase;
        color: $sold-out-text;
      }
    }
  }
}
