@import '../configuration/config';

.equote__review-email-submit-quote {
  .equote__navigation--arrows {
    white-space: nowrap;
    overflow-y: auto;

    @include OnIE() {
      overflow: hidden;
    }

    img {
      width: 40px;
      height: 40px;
      margin-left: 7px;
    }
  }
}
