@import '../configuration/config';

.maintenance-page {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;

  @include MQ(lg) {
    flex-direction: row;
    padding: 64px 16px;
  }

  .maintenance-page-copy-container {
    flex: 1 0 45%;
    padding: 32px 16px;
    .maintenance-page-copy {
      max-width: 880px;
      margin: 0 auto;
    }
    h1 {
      text-transform: uppercase;
      text-align: center;
      @include MQ(lg) {
        font-size: 36px;
      }
    }

    @include MQ(lg) {
      padding: 64px 32px 0;
    }
  }

  .maintenance-page-image-container {
    flex: 1 0 55%;
    img {
      width: 100%;
    }
  }
}
