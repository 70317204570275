@import '../configuration/config';

.equote__sailings-ship-modal {
  .u-modal__header {
    justify-content: center;

    @include MQ(lg) {
      padding: $col-gutter * 2 $col-gutter * 2 $col-gutter - 10 $col-gutter * 2;
    }

    h2 {
      padding: 0 $col-gutter * 4 0 0;
      position: relative;
      text-align: left;
      width: 100%;

      @include MQ(lg) {
        padding: 0 $col-gutter * 4;
        text-align: center;
      }

      .u-modal__close {
        position: absolute;
        right: 0;
        top: 50%;
        @include align-y-axis(-50%);
      }
    }
  }

  .u-modal__body {
    @include MQ(lg) {
      padding: $col-gutter - 10 * 2 $col-gutter * 2 $col-gutter * 2 $col-gutter *
        2;
    }
  }

  .equote__sailings-ship-modal-content {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .equote__sailings-ship-modal-image {
    flex: 0 0 100%;
    margin: 0 0 $col-gutter 0;
    padding: 0;
    width: 100%;

    @include MQ(lg) {
      flex: 0 0 60%;
      margin: 0 0 $col-gutter + 10 0;
      width: 60%;
    }

    img {
      width: 100%;
    }
  }

  .equote__sailings-ship-modal-details {
    flex: 0 0 100%;
    margin: 0 0 $col-gutter 0;
    width: 100%;

    @include MQ(lg) {
      flex: 0 0 40%;
      margin: 0 0 $col-gutter + 10 0;
      width: 40%;
    }
  }

  .equote__sailings-ship-modal-details-row {
    display: flex;
    flex-wrap: wrap;
    padding: 1px $col-gutter - 5;

    &:nth-child(even) {
      background-color: $light-grey;
    }

    .ship-label {
      font-size: $font-size-base - 2;
      font-weight: $font-weight-medium;
      width: 35%;
    }

    .ship-value {
      font-size: $font-size-base - 2;
      width: 65%;
    }
  }

  .equote__sailings-ship-modal-body {
    font-size: $font-size-base - 2;
    width: 100%;

    @include MQ(lg) {
      width: 70%;
    }
  }
}
