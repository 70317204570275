@import '../configuration/config';

.equote__package-filters-form {
  background-color: $light-grey;
  margin: 0 0 $col-gutter 0;
  padding: 40px 0px;
  position: relative;

  .equote__package-filters-form-groups {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 30px;

    @include MQ(md) {
      flex-direction: row;
    }

    @include MQ(xl) {
      flex-wrap: nowrap;
      flex-direction: row;
    }

    @include MQ(xxl) {
      padding: 0px;
    }
  }

  .equote__package-filters-form-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include MQ(md) {
    }

    @include MQ(lg) {
      max-width: 300px;
    }

    &--margin {
      margin-top: -9px;
    }
    &:nth-of-type(2) {
      // background-color: red;
      width: 100%;

      @include MQ(md) {
        width: 50%;
      }
    }
    &:nth-of-type(3) {
      // background-color: blue;
      width: 100%;

      @include MQ(md) {
        width: 50%;
      }
    }
    &:nth-of-type(4) {
      width: 100%;

      @include MQ(md) {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      @include MQ(lg) {
        width: 100%;
        display: block;
      }
      .equote__package-filters-form-field {
        width: 100%;

        @include MQ(md) {
          width: 50%;
        }

        @include MQ(lg) {
          width: 100%;
        }
      }
    }
  }

  .equote__package-filters-title {
    width: 100%;
    max-width: 100%;

    @include MQ(xl) {
      max-width: 92px;
      margin-right: 10px;
    }
  }

  .equote__package-filters-submit {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;

    @include MQ(md) {
      margin-right: 0;
    }

    @include MQ(xl) {
      margin-left: 0;
    }
  }

  .equote__package-filters-form-field {
    margin: -1px 0 $col-gutter - 5 0;

    &:last-of-type {
      margin: 1px 0 0 0;
    }

    h6 {
      color: $dark-grey;
      text-transform: uppercase;
    }

    .btn-primary {
      text-transform: uppercase;
      width: 100%;
      padding: 12px 20px;

      @include MQ(xs) {
        padding: 12px 70px;
      }
    }
    select,
    span {
      @include MQ(lg) {
        max-width: 280px;
      }
    }
  }

  .equote__package-filters-form-custom-date {
    text-transform: uppercase;
  }

  .equote__package-filters-toggle-date {
    color: $tri-brand-blue-two;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    text-underline-position: under;
    transition: background-color 0.25s linear;

    @include MQ(lg) {
      padding: $col-gutter - 15 0;
    }

    &:hover {
      color: darken($tri-brand-blue-two, 20%);
    }
  }
}
