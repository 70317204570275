@import '../configuration/config';

// ========================================================================
// Utilities & Helpers
// ========================================================================
.equote__generate-offers,
.ReactModalPortal {
  // Container
  .u-content-container {
    margin: 0 auto 0;
    max-width: $content-max-width;
    width: 100%;

    &--alt {
      max-width: $content-max-width-alt;
    }
  }

  .u-uppercase {
    text-transform: uppercase;
  }

  .u-reset-margin {
    margin-top: -9px;
    @include MQ(md) {
      margin-top: 18px;
    }
  }

  .u-capitalize {
    text-transform: capitalize;
  }

  .u-flex-container {
    display: flex;

    &--center {
      display: flex;
      align-items: center;
    }

    &--space-between {
      justify-content: space-between;
    }

    &--justify-start {
      justify-content: flex-start;
    }

    &--justify-end {
      justify-content: flex-end;
    }

    &--justify-center {
      justify-content: center;
    }

    &--align-center {
      align-items: center;
    }
  }

  .u-pseudo-table {
    position: absolute;
    top: 120px;
    z-index: 14;
  }

  // Loading Spinner
  .u-loading-spinner {
    text-align: center;
    padding: 128px 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include OnIE {
      height: 60vh;
      flex: auto;
      display: block;
    }

    img {
      width: 110px;
      height: 110px;
      margin: 0 auto;
    }
  }

  // Margin & Padding
  .u-remove-padding {
    padding: 0;
  }

  .u-remove-margin {
    margin: 0;
  }

  .u-add-margin {
    margin: 30px 0;
  }

  .u-margin-l-auto {
    margin-left: auto;
  }

  .u-margin-r-auto {
    margin-right: auto;
  }

  .u-add-padding {
    padding: 15px;

    @include MQ(md) {
      padding: 25px;
    }
  }

  // Visibility
  .u-element-remove {
    display: none;
  }

  .u-element-hidden {
    visibility: hidden;
  }

  .u-text-hidden {
    overflow: hidden;
    text-indent: -9999px;
    visibility: hidden;
    white-space: nowrap;
  }

  .u-overflow-x-scroll {
    overflow-x: auto;
    padding-bottom: 30px;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }

  .u-overflow-y-scroll {
    overflow-y: auto;
    height: 200px;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    @include MQ(sm) {
      height: 100%;
    }
  }

  // Image
  .u-img-responsive {
    display: block;
    width: 100%;
  }

  .u-brand-loader {
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      display: block;
      height: 125px;
      width: auto;
    }
  }

  // Text
  .u-txt-center {
    text-align: center;
  }

  .u-txt-left {
    text-align: left;
  }

  .u-txt-right {
    text-align: right;
  }

  .u-txt-italic {
    font-style: italic;
  }

  // Font
  .u-font-xxs {
    font-size: $font-size-base - 5;
  }

  .u-font-xs {
    font-size: $font-size-base - 4;
  }

  .u-font-small {
    font-size: $font-size-base - 2;
  }

  .u-font-medium {
    font-size: $font-size-base - 2;
  }

  .u-font-large {
    font-size: $font-size-base + 3;
  }

  .u-font-bold {
    font-weight: $font-weight-bold;
  }

  .u-font-error {
    color: #ff0000;
    font-size: 12px;
    font-style: italic;
    margin: 10px 0 0 0;
  }

  .u-font-body {
    font-family: $sans-serif-stack;
    font-weight: 400;
  }

  .u-small-title {
    font-size: $font-size-base + 6;
    font-weight: $font-weight-regular;
  }

  // Border
  .u-remove-border {
    border: none;
  }

  .u-remove-border-radius {
    border-radius: 0;
  }

  // Background
  .u-bg-image {
    background-attachment: scroll;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .u-bg-black {
    background-color: $black;
  }

  .u-bg-white {
    background-color: $white;
  }

  .u-bg-dark-grey {
    background-color: $dark-grey;
  }

  .u-bg-medium-grey {
    background-color: $medium-grey;
  }

  .u-bg-light-grey {
    background-color: $light-grey;
  }

  // Text Color
  .u-color-black {
    color: #000;
  }

  .u-color-white {
    color: #fff;
  }

  .u-font-sold-out {
    color: $error-red;
  }

  .u-font-uppercase {
    text-transform: uppercase;
  }

  .u-font-initial {
    text-transform: initial;
  }

  .u-font-lowercase {
    text-transform: lowercase;
  }

  .u-transparent {
    background-color: transparent;
  }

  // Basic Header Section
  .u-page-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $col-gutter * 2 + 10 0;
    position: relative;
    text-align: center;

    @media screen and ($bp-min-xl) {
      padding: $col-gutter * 2 + 10 $col-gutter * 10 $col-gutter * 2 + 10;
    }

    .btn {
      margin-bottom: $col-gutter;
      text-transform: uppercase;
      background-color: white;
      white-space: wrap;

      @include MQ(sm) {
        white-space: nowrap;
      }

      &:hover {
        background-color: $lighter-grey;
      }

      @media screen and ($bp-min-xlr) {
        position: absolute;
        margin-bottom: 0;
        left: $col-gutter + 10;
        top: auto;
      }

      @media screen and ($bp-min-xxl) {
        left: 0;
      }

      .u-page-header__back-button-inner {
        display: flex;
        align-items: center;

        .u-page-header__back-button-icon-container {
          border-right: 1px solid $grey;
          padding-right: 8px;
          margin-right: 8px;
          display: flex;
          align-items: center;
        }
      }
    }

    h2 {
      margin: 0 0 $col-gutter - 10 0;
      padding: 0 30px;

      @include MQ(md) {
        padding: 0;
      }
    }

    h2,
    p {
      width: 100%;
    }
  }

  .u-position-sticky {
    position: -webkit-sticky !important;
    position: -moz-sticky !important;
    position: -ms-sticky !important;
    position: -o-sticky !important;
    position: sticky !important;
    top: 0;
  }

  .u-modal__header--sticky {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: $white;
    cursor: pointer;
    z-index: 15;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include OnIE() {
      padding: 0;
    }

    svg {
      margin-left: 5px;
      width: 35px;
      height: auto;
      margin-top: 4px;
    }

    .u-modal__close {
      transform: none;
      padding: 5px 20px;

      @include OnIE() {
        height: 50px;
      }

      @include MQ(lg) {
        padding: 10px 20px;
      }
    }

    &:hover {
      background-color: $tri-brand-blue-one;

      .u-modal__close {
        color: $white;
      }

      path {
        fill: $white;
      }
    }

    @include MQ(md) {
      top: -20px;
      right: -20px;
      padding: 0 20px;
    }

    @include MQ(lg) {
      top: -25px;
      right: 0;
    }
  }

  .u-link {
    color: $brand-base-royal;
    text-decoration: underline;
  }

  .u-magin-auto {
    margin: 0 auto !important;
  }

  .u-virtual-tour-trigger {
    appearance: none;
    border: 0;
    cursor: pointer;
    padding: 0;

    border-radius: 6px;

    .u-virtual-tour-trigger-content {
      display: flex;
      align-items: stretch;

      .u-virtual-tour-image-wrapper {
        border-radius: 6px 0 0 6px;
        background: #000;
        display: flex;
        z-index: 2;
        .u-virtual-tour-image-container {
          flex: 1 0;
          img {
            width: 48px;
          }
        }
      }

      .u-virtual-tour-trigger-text-container {
        display: flex;
        align-items: center;
        margin-left: -16px;
        padding-left: 32px;
        background: #000;
        flex: 1;
        border-radius: 0 6px 6px 0;
        p {
          color: #fff;
          line-height: 1;
          text-align: left;
        }
      }
    }
    &.royal {
      background: #15264c;

      .u-virtual-tour-trigger-content {
        border-radius: 6px 0 0 4px;
        .u-virtual-tour-image-wrapper {
          background: #15264c;
        }
        .u-virtual-tour-trigger-text-container {
          background: #15264c;
        }
      }
    }
    &.celebrity {
      background-color: #fff;
      .u-virtual-tour-trigger-content {
        .u-virtual-tour-image-wrapper {
        }
        .u-virtual-tour-trigger-text-container {
        }
      }
    }
  }
}
