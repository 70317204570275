@import url('https://p.typekit.net/p.css?s=1&k=fhs0oyn&ht=tk&f=25136&a=16947336&app=typekit&e=css');

@import '../configuration/config';

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../../../assets/fonts/lato-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
    url('../../../assets/fonts/lato-v14-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-300.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../../../assets/fonts/lato-v14-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/lato-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../../../assets/fonts/lato-v14-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-regular.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../../assets/fonts/lato-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
    url('../../../assets/fonts/lato-v14-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-700.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../../assets/fonts/lato-v14-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../../../assets/fonts/lato-v14-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'),
    url('../../../assets/fonts/lato-v14-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-900.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('../../../assets/fonts/lato-v14-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Kapra';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/kapra.eot');
  src: local('Lato Light'), local('Lato-Light'),
    url('../../../assets/fonts/kapra.woff') format('woff'),
    url('../../../assets/fonts/kapra.woff') format('woff'),
    url('../../../assets/fonts/kapra.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../../../assets/fonts/Roboto-Regular.ttf')
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('../../../assets/fonts/Roboto-Medium.ttf')
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('../../../assets/fonts/Roboto-Bold.ttf')
}

@font-face {
  font-family: 'RobotoLight';
  font-weight: normal;
  font-style: normal;
  src: url('../../../assets/fonts/Roboto-Light.woff') format('woff'),
    url('../../../assets/fonts/Roboto-Light.woff') format('woff'),
    url('../../../assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 500;
  src: url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
}

p {
  font: $font-base;
  line-height: 1.5;
  margin: 0;

  @include MQ(sm) {
    margin: 0 0 $mb-base 0;
    line-height: $line-height-copy;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $font-color-heading;
  font-family: $sans-serif-stack;
  font-weight: $font-weight-heading;
  line-height: $line-height-heading;
  margin: 0 0 $mb-base 0;
}

h1,
.h1 {
  font-style: $font-weight-base + 6;

  @media screen and ($bp-min-md) {
    font-size: $font-size-base + 8;
  }

  @media screen and ($bp-min-lg) {
    font-size: $font-size-base + 14;
  }
}

h2,
.h2 {
  font-style: $font-weight-base + 6;

  @media screen and ($bp-min-md) {
    font-size: $font-size-base + 8;
  }

  @media screen and ($bp-min-lg) {
    font-size: $font-size-base + 14;
  }
}

h3,
.h3 {
  font-size: $font-size-base + 5;

  @media screen and ($bp-min-md) {
    font-size: $font-size-base + 7;
  }

  @media screen and ($bp-min-lg) {
    font-size: $font-size-base + 10;
  }
}

h4,
.h4 {
  font-size: $font-size-base + 4;

  @media screen and ($bp-min-md) {
    font-size: $font-size-base + 6;
  }

  @media screen and ($bp-min-lg) {
    font-size: $font-size-base + 8;
  }
}

h5,
.h5 {
  font-size: $font-size-base + 1;

  @media screen and ($bp-min-md) {
    font-size: $font-size-base + 2;
  }

  @media screen and ($bp-min-lg) {
    font-size: $font-size-base + 3;
  }
}

h6,
.h6 {
  font-size: $font-size-base;
  margin: 0 0 16px 0;
}

// Package Detail Fonts

@font-face {
  font-family: 'Lato-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../../../assets/fonts/lato-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
    url('../../../assets/fonts/lato-v14-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-300.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../../../assets/fonts/lato-v14-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/lato-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
    url('../../../assets/fonts/lato-v14-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-regular.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../../assets/fonts/lato-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
    url('../../../assets/fonts/lato-v14-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-700.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../../assets/fonts/lato-v14-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lato-Black';
  font-style: normal;
  font-weight: 900;
  src: url('../../../assets/fonts/lato-v14-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'),
    url('../../../assets/fonts/lato-v14-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../assets/fonts/lato-v14-latin-900.woff')
      format('woff'),
    /* Super Modern Browsers */
      url('../../../assets/fonts/lato-v14-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('../../../assets/fonts/lato-v14-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../assets/fonts/lato-v14-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

// ROYAL HEADERS
@font-face {
  font-family: 'Kapra';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/kapra.eot');
  src: local('Lato Light'), local('Lato-Light'),
    url('../../../assets/fonts/kapra.woff') format('woff'),
    url('../../../assets/fonts/kapra.woff') format('woff'),
    url('../../../assets/fonts/kapra.ttf') format('truetype');
}
// ROYAL PRIMARY FONT
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  src: url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../../assets/fonts/Roboto-Thin.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/Roboto-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('../../../assets/fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../../assets/fonts/Roboto-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../../../assets/fonts/Roboto-Black.ttf') format('opentype');
}

@font-face {
  font-family: 'Biotif-Regular';
  font-style: normal;
  font-weight: 500;
  src: url('../../../assets/fonts/Biotif-Regular.otf') format('opentype');
}

.equote__package-details {
  p {
    font-family: $font-base;
    line-height: $line-height-copy;
    font-size: $font-size-base;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $font-color-heading;
    font-family: $sans-serif-stack;
    font-weight: $font-weight-heading;
    line-height: $line-height-heading;
    margin: 0 0 $mb-base 0;
    letter-spacing: 0.05em;
  }

  h1,
  .h1 {
    font-size: $font-size-base + 14;
  }

  h2,
  .h2 {
    font-size: $font-size-base + 14;
  }

  h3,
  .h3 {
    font-size: $font-size-base + 10;
  }

  h4,
  .h4 {
    font-size: $font-size-base + 8;
  }

  h5,
  .h5 {
    font-size: $font-size-base + 3;
  }

  h6,
  .h6 {
    font-size: $font-size-base;
    margin: 0 0 16px 0;
  }

  .section-title {
    text-transform: uppercase;
    padding: 0 20px;

    @include MQ(md) {
      font-size: 40px;
      padding: 0 64px;
    }

    @include MQ(lg) {
      font-size: 40px;
      padding: 0;
    }
  }

  .item {
    &--title {
      font-size: $font-size-base - 2;
      font-weight: 700;
      color: $tri-brand-blue-one;
      text-transform: uppercase;
      font-family: $sans-serif-stack-bold;
    }
    &--info {
      font-size: $font-size-base - 2;
      font-family: $sans-serif-stack-light;
    }
  }
}
.royal {
  * {
    font-family: $royal-primary-font;
  }

  p {
    font-family: $royal-primary-font;
    font-weight: $royal-primary-font-weight;
    color: $dark-grey;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $royal-blue;
    font-family: $royal-header-font;
  }

  .section-title {
    padding: 0 20px;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;

    @include MQ(lg) {
      padding: 0 32px;
    }
  }
}

//Celebrity X Cruise Equote
.celebrity {
  * {
    font-family: $celebrity-primary-font;
  }
  p {
    font-family: $celebrity-primary-font;
    font-weight: $celebrity-font-weight-regular;
    color: $celebrity-grey;
    font-size: 16px;
    line-height: 1.4em;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $celebrity-blue;
    font-family: $celebrity-header-font;
    font-weight: $celebrity-font-weight-light;
  }

  .section-title {
    @include MQ(lg) {
      padding: 0 32px;
    }
  }
}
