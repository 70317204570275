@import '../configuration/config';

.equote__review-email-packages {
  .equote__review-email-package {
    padding: $col-gutter;

    @media screen and ($bp-min-md) {
      padding: 38px 60px 27px;
    }

    &.brand-z {
      .equote__review-email-package-btn {
        background-color: $brand-azamara-orange;
        color: $white;
        text-transform: uppercase;
      }
    }

    &.brand-multi {
      .equote__review-email-package-btn {
        background-color: $brand-royal-gold;
        color: $black;
        text-transform: uppercase;
      }

      .equote__review-email-social {
        h4 {
          font-family: $font-proxima-nova;
          color: $royal-blue;
        }
      }

      .btn {
        font-family: $font-proxima-nova;
      }
    }

    &.brand-c {
      .equote__review-email-package-banner {
        h6 {
          font-family: $font-roboto;
        }
      }

      .equote__review-email-package-col-title,
      .equote__review-email-package-col-value {
        font-family: $font-roboto;
      }

      .equote__review-email-package-btn {
        background-color: $brand-celebrity-blue;
        color: $white;
        text-transform: uppercase;
      }
      .equote__review-email-package-actions {
        .btn:not(.btn-white) {
          background-color: $celebrity-blue;
        }
        .btn-white {
          &:hover {
            background-color: $celebrity-blue;
          }
        }
        .btn {
          font-family: $font-roboto;
        }
      }

      .equote__review-email-social {
        h4 {
          font-family: $font-roboto;
          color: $celebrity-grey;
        }
      }
    }

    &.brand-r {
      .equote__review-email-package-btn {
        background-color: $brand-royal-gold;
        color: $black;
        text-transform: uppercase;
      }

      .equote__review-email-social {
        h4 {
          font-family: $font-proxima-nova;
          color: $royal-blue;
        }
      }
      .btn-white {
        &:hover {
          background-color: $royal-blue;
        }
      }
      .btn {
        font-family: $font-proxima-nova;
      }
    }

    .btn {
      margin-top: 30px;
      max-width: 290px;
      text-align: center;
    }

    .equote__package-details-outline--land-program {
      .equote__package-details-outline--land-program-flag {
        margin-right: 6px;
        padding: 4px 22px 4px 8px;
        .equote__package-details-outline--land-program-flag--img-container {
          img {
            width: 24px;
            height: auto;
          }
        }
        p {
          margin-left: 8px;
          font-size: 14px;
        }
      }
      p {
        font-size: 16px;
        margin: 0;
        z-index: 2;
      }
    }
    .equote__review-email-package--cruisetour {
      display: flex;
      align-items: center;
      background-color: $light-grey;
      p {
        margin: 0;
      }
      .equote__review-email-package--cruisetour-flag {
        background-color: $royal-bright;
        padding: 0 10px 0 10px;
        position: relative;
        overflow: hidden;

        @include MQ(sm) {
          padding: 0 45px 0 20px;
        }

        p {
          margin: 0;
          color: white !important;
          font-size: 16px !important;
          padding: 10px 10px 10px 0;
          height: 100%;

          @include MQ(sm) {
            padding: 10px;
          }
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -33px;
          height: 120px;
          width: 40px;
          background: $light-grey;

          @include MQ(sm) {
            right: -13px;
          }
        }

        &:before {
          top: -24px;
          transform: rotate(-20deg);
        }

        &:after {
          transform: rotate(20deg);
          bottom: -24px;
        }
        @at-root {
          .equote__review-email-package.brand-z {
            .equote__review-email-package--cruisetour {
              .equote__review-email-package--cruisetour-flag {
                background-color: $brand-azamara-blue;
              }
            }
          }
          .equote__review-email-package.brand-c {
            .equote__review-email-package--cruisetour {
              .equote__review-email-package--cruisetour-flag {
                background-color: $brand-celebrity-blue;
              }
            }
          }
          .equote__review-email-package.brand-r {
            .equote__review-email-package--cruisetour {
              .equote__review-email-package--cruisetour-flag {
                background-color: $tri-brand-blue-one;
              }
            }
          }
        }
      }
    }
  }

  .equote__review-email-package-hero {
    min-height: 275px;
  }

  .equote__review-email-package-banner {
    align-items: center;
    background-color: #ececec;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 10px;

    @include MQ(md) {
      flex-direction: row;
    }

    @include MQ(lg) {
      flex-wrap: nowrap;
    }

    h6 {
      color: $royal-blue;
      font-weight: 600;
      order: 2;
      text-align: center;
      line-height: 180%;

      @include MQ(md) {
        order: 1;
        text-align: left;
      }
      @include MQ(lg) {
        padding-right: 10px;
      }
    }
  }

  .equote__review-email-package-banner-brand {
    max-width: 115px;
    max-height: 55px;
    order: 1;
    margin-bottom: 15px;

    @include MQ(md) {
      margin-bottom: 0;
      order: 2;
    }

    img {
      max-height: 100%;
      width: 100%;
    }
  }

  .equote__review-email-package-col {
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-wrap: nowrap;
  }

  .equote__review-email-package-col-title {
    color: $royal-blue;
    width: 50%;
    flex: 0 0 50%;
    font-size: $font-size-base - 3;
    line-height: 180%;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include MQ(sm) {
      overflow: visible;
      text-overflow: clip;
      padding: 5px 10px;
    }
    @include MQ(md) {
      flex: 0 0 50%;
    }
  }

  .equote__review-email-package-col-value {
    color: $royal-blue;
    padding: 5px 10px;
    font-size: $font-size-base - 1;
    line-height: 180%;
    font-weight: 600;
    &.best-price {
      line-height: 20px;
      padding: 16px 10px;
    }
    .taxes {
      font-size: 12px;
      color: #4a4a4a;
    }
  }

  .equote__review-email-package-actions {
    align-items: center;
    display: flex;
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 27px 0 39px;

    .btn {
      margin: 0;
      text-transform: uppercase;
      padding: 15px 24px;
      color: $royal-blue;
    }

    .btn-white {
      border: 1px solid $grey;
      margin-top: 0;
    }
  }

  .equote__review-email-social-icons {
    a {
      cursor: default;
    }
  }

  .equote__review-email-package-btn {
    cursor: default;
  }
}
