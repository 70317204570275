@import '../configuration/config';

.equote-primary-select-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $light-grey;
  border: 1px solid $grey;
  flex: 1 0;
  box-shadow: inset 0 0 1px rgba($color: $black, $alpha: 0.1);
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  transition: 0.3s all ease-in-out;

  @include MQ(lg) {
    max-width: 210px;
  }

  select {
    border: none;
    padding: 12px;
    font-size: 13px !important;
    color: $medium-grey;
    font-weight: 700;
    background-image: url('../../../assets/icons/arrow-down.svg');
    background-position: center right 8px;
    background-repeat: no-repeat;
    margin: 0;
  }

  &:hover {
    background-color: $grey;
  }

  .equote-primary-select-button-container-option-icon {
    max-width: 15px;
    z-index: 0;
    @include OnIE() {
      max-width: 35px;
    }
  }
}
