@import '../configuration/config';

.equote__review-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &--col {
    padding: 0 $col-gutter;
    margin: 0 0 $col-gutter * 2 0;
    width: 100%;

    @include MQ(md) {
      padding: 0 $col-gutter + 10;
    }

    @include MQ(xl) {
      margin: 0;
      padding: 0 $col-gutter * 2;
      width: 50%;
    }
  }
}

.equote__review-configure-email {
  &--header {
    display: flex;
    align-items: center;
    margin-bottom: $col-gutter + 12;

    &--img-container {
      width: 64px;
      height: 64px;
      border: 1px solid $grey;
      border-radius: 64px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $col-gutter;
    }

    h6 {
      color: $medium-dark-grey;
      font-size: $font-size-base + 9;
      font-weight: 400;
    }
  }

  .equote__review-configure-col {
    margin-bottom: 32px;
    label {
      color: $tri-brand-blue-two;
      text-transform: uppercase;
    }
    input,
    textarea {
      width: 100%;
      max-width: 614px;
      border: $border-grey;

      &::-webkit-input-placeholder {
        color: $medium-dark-grey;
        font-size: $font-size-small;
      }
    }
    input {
      height: 60px;
    }

    p {
      color: $medium-dark-grey;
      font-size: $font-size-base - 3;
    }
  }

  .equote__review-configure-email-terms-check {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: $col-gutter - 10 $col-gutter $col-gutter - 10 $col-gutter - 10;
    margin-top: 30px;

    @include MQ(md) {
      margin-top: 0;
    }

    .equote__review-configure-email-terms-check-icon {
      background: $white;
      height: 24px;
      margin: 0 $col-gutter 0 0;
      position: relative;
      width: 24px;



      &.active {
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          transform: translate(-50%, -50%);
        }
      }
    }
    .equote__review-configure-email_send_to_agent_checkmark {
      border: 1px solid;
    }
  }
}
