@import '../configuration/config';

.equote__navigation {
  background-color: $white;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20;

  .equote__navigation-content {
    align-items: center;
    border-bottom: 1px solid $medium-grey;
    border-top: 1px solid $medium-grey;
    display: none;
    flex-wrap: wrap;
    height: 70px;
    justify-content: center;
    padding: 0 $col-gutter;
    text-transform: uppercase;
    position: relative;
    min-width: 180px;

    @include MQ(sm) {
      height: 90px;
    }

    @include MQ(md) {
      display: flex;
    }

    .sailing-list-modal-trigger {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      white-space: nowrap;
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .equote__navigation--arrows {
    .pointer {
      height: 70px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0;
      background-color: $white;
      min-width: 180px;

      @include MQ(sm) {
        height: 90px;
      }

      &.active {
        background-color: $tri-brand-blue-one;
        color: $white;
      }
      &:after {
        content: '';
        position: absolute;
        background-color: white;
        bottom: 50%;
        width: 33%;
        height: 33%;
        right: 0;
        transform-origin: bottom right;
        transform: rotate(55deg) translate(0, 1px);
        border-bottom: $border-medium;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: white;
        top: 50%;
        width: 33%;
        height: 33%;
        right: 0;
        transform-origin: top right;
        transform: rotate(-55deg) translate(0, -1px);
        border-top: $border-medium;
      }

      .pointer-inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 $grid-unit * 6 0 $grid-unit * 4;

        @include MQ(md) {
          min-width: 220px;
        }
      }

      &:not(:last-child) {
        border-top: $border-medium;
        border-bottom: $border-medium;
      }

      &:last-child {
        .pointer-inner {
          border-top: $border-medium;
          border-bottom: $border-medium;
        }
      }
    }
  }
}
