@import '../configuration/config';

.equote__package-details-related-sailings-slider {
  @include MQ(lg) {
    display: none;
  }

  &__wrapper {
    border: $grey 1px solid;
  }

  &__row-buttons {
    display: flex;
    justify-content: space-between;

    .btn {
      flex: 1;
      text-align: center;
      margin: 20px 0;
      font-size: 0.9em !important;
      white-space: nowrap;
    }
    .btn-info {
      letter-spacing: 0 !important;
      background: none !important;
      text-decoration: underline;
      color: $tri-brand-blue-one !important;
      text-underline-position: under;
    }
  }

  &__text {
    margin: 0 50px 0 20px;
    color: $medium-grey !important;
    font-family: $sans-serif-stack-light !important;
    font-size: 0.9em !important;
    line-height: 1.4 !important;

    @include MQ(md) {
      margin: 40px 64px 0 64px;
    }
  }

  &__row {
    display: flex;

    &:nth-child(odd) {
      background-color: $light-grey;
    }
  }

  &__row-head {
    width: 50%;
    text-transform: uppercase;
    padding: 10px 20px;
    color: $azamara-blue;
    font-family: $sans-serif-stack !important;
  }

  &__row-head--indent {
    padding-left: 30px;
    text-transform: none;
    color: $medium-dark-grey;
  }

  &__row-body {
    width: 50%;
    padding: 10px 20px;
    font-family: $sans-serif-stack-light !important;
  }

  &__row-price,
  &__row-promo {
    padding: 0;
    margin: 0;
    font-family: $sans-serif-stack-light !important;
  }

  &__row-price {
    font-family: $sans-serif-stack !important;
  }

  &__row-promo {
    color: $medium-grey !important;
    font-size: 0.9em !important;
  }

  & &__row-sold-out p {
    color: $error-red;
    margin: 0;
  }

  .slick-slider {
    overflow: hidden;
    padding: 0;

    @include MQ(lg) {
      padding: 0 64px;
    }
  }

  .slick-list {
    overflow: visible;
    width: 100%;

    @include MQ(md) {
      margin-left: 36px;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-prev {
    left: 6px;
    &:before {
      display: none;
    }
  }

  .slick-next {
    right: 22px;
    &:before {
      display: none;
    }
  }

  .slick-slide {
    padding: 0 65px 0 28px;

    @include MQ(md) {
      padding: 0 41px 0 28px;
    }

    > div {
      width: 100%;
    }

    &.slick-current {
      @include MQ(md) {
        padding-right: 41px;
        margin-right: -41px;
      }
    }

    display: flex;
    height: auto;
    transition: all 0.2s ease;
    &:not(.slick-active) {
      opacity: 0.5;
      transform: scale(0.9);
      transform: translateX(-65px);

      @include MQ(md) {
        transform: translateX(-45px);
      }
    }
  }
}
