@import '../configuration/config';

.equote__search__modal-header {
  flex-direction: column-reverse;
  align-items: center;

  &.u-modal__header {
    padding-bottom: 0;
    margin: 0;
  }

  .u-modal__close {
    align-self: flex-end;
  }

  .equote__search__title-container {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: $col-gutter / 2;

    h6 {
      margin: 0 0 0 16px;
    }

    .equote__search-filter-icon {
      border: 1px solid $grey;
      border-radius: 100%;
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .equote__search-filter-title {
      line-height: 52px; /* identical to box height */
      letter-spacing: 0.05em;
      cursor: default;
    }
  }

  .equote__search-filter-close {
    line-height: 29px;
    letter-spacing: 0.15em;

    &-icon {
      margin-top: 4px;
    }
  }

  @media screen and ($bp-min-xl) {
    flex-direction: row;
  }
}

.equote__search-filters {
  position: relative;

  &-heading {
    max-width: 1072px;
    margin: auto;

    &--inner {
      padding: $col-gutter * 4 $col-gutter + 10 $col-gutter * 3 $col-gutter + 10;
    }

    h2 {
      margin: 0 0 $col-gutter - 5 0;
    }
  }

  &-content {
    align-items: flex-start;

    @media screen and ($bp-min-xl) {
      flex-direction: row;
    }
  }

  .equote__search-filter-header {
    background-color: $light-grey;

    &--inner {
      display: flex;
      flex-direction: column;
      @media screen and ($bp-min-lg) {
        flex-direction: row;
      }
    }
  }

  .equote__search-filter-group {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 33.3333%;
    justify-content: center;
    padding: $col-gutter * 2 $col-gutter + 10;
    text-align: center;

    @include MQ(md) {
      flex: auto;
    }

    h6 {
      width: 100%;
      margin-bottom: $grid-unit * 4;
    }

    @media screen and ($bp-min-lg) {
      padding: 0 $col-gutter + 10 $col-gutter * 2 $col-gutter + 10;
      max-width: 33.3333%;
    }
  }

  .equote__search-filter-icon {
    display: none;
    @media screen and ($bp-min-lg) {
      align-items: center;
      background-color: $white;
      border: 1px solid $grey;
      border-radius: 50%;
      display: flex;
      height: 66px;
      justify-content: center;
      margin: -$col-gutter - 13px 0 $col-gutter 0;
      width: 66px;

      img {
        max-width: 30px;
      }
    }
  }

  .equote__search-filter {
    align-content: space-between;
    background: $white;
    border: 1px solid $grey;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: $col-gutter + 10 $col-gutter + 10 $col-gutter + 35 $col-gutter + 10;
    width: 100%;
    transition: 0.2s all;

    &.brands {
      background: transparent;
      border: 0;
      padding-top: 0;
    }

    @media screen and ($bp-min-lg) {
      max-height: 270px;
    }

    &:hover {
      background-color: $lighter-grey;
    }

    &--multi {
      align-content: initial;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 $col-gutter - 10 0;
      min-height: 60px;
      padding: $col-gutter - 10 $col-gutter + 10;

      &:last-of-type {
        margin: 0;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      img {
        max-width: 250px;
        max-height: 42px;
      }
    }
    .equote__search-filter-description {
      padding-top: $col-gutter;
      padding-bottom: $col-gutter;
      width: 75%;
      line-height: 25px;
    }
  }

  .equote__search-filter-title {
    align-items: center;
    color: $tri-brand-blue-two;
    display: flex;
    text-transform: uppercase;
  }

  .equote__search-filter-arrow {
    border-left: 1px solid $grey;
    margin: 0 0 0 $col-gutter - 10;
  }

  .equote__search-filters-form {
    align-content: center;
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    padding: $col-gutter + 10;
    width: 100%;

    &-promo {
      @include OnIE() {
        width: 100%;
      }
    }

    h6 {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @media screen and ($bp-min-xl) {
        padding: 0 $col-gutter * 2 + 10;
      }
      img {
        padding: 0 $col-gutter - 5 0 0;
      }
    }

    .btn-primary {
      margin: $col-gutter + 10 0 0 0;
      width: 442px;

      @media screen and ($bp-max-md) {
        width: 100%;
      }
    }
  }

  .equote__search-filters-form-field {
    background-color: $light-grey;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;

    &:nth-child(odd) {
      background-color: $white;
    }

    @media screen and ($bp-min-xl) {
      padding: $col-gutter $col-gutter $col-gutter $col-gutter * 3;
      flex-direction: row;
      align-items: center;
    }

    .equote__search-filters-form-currency {
      select {
        text-transform: capitalize;
      }
    }

    .equote__search-filters-form-field-title {
      color: $medium-dark-grey;
      padding: 5px $col-gutter - 5 18px 0;
      text-transform: uppercase;
      font-size: $font-size-base + 1;

      @media screen and ($bp-min-xl) {
        width: 15%;
        padding: $col-gutter + 19 0;
        margin-right: $col-gutter * 2 + 10;
        flex-shrink: 0;
      }
    }
  }

  .equote__search-filters-form-field-input {
    input {
      display: inline;
      padding: 15px;
      vertical-align: top;
    }

    button {
      vertical-align: top;
      text-transform: uppercase;
    }

    &:last-of-type {
      margin: 0;
    }

    select {
      margin: 0 $col-gutter + 10 0 0;
    }
    img {
      width: 70px;
      height: 48px;
      padding: 0 0 0 20px;
    }

    &.equote__search-filters-loyalty {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      input {
        background-color: $white;
        border-bottom: 1px solid $grey;
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
        border-top: 1px solid $grey;
        flex: 1;
        order: 1;

        @include MQ(md) {
          flex: 0 1 180px;
        }
      }

      img {
        display: block;
        margin: 0 auto;

        @include MQ(sm) {
          margin: 0;
        }
        order: 2;
      }

      button {
        flex: 0 1 120px;
        margin: 0 auto;
        order: 4;

        @include MQ(sm) {
          margin: 0;
          order: 3;
        }
      }
      p {
        width: 100%;
        order: 3;
        text-align: center;

        @include MQ(sm) {
          order: 4;
          text-align: left;
        }
      }
    }
  }

  .equote__search-filters-form-custom-date {
    text-transform: uppercase;

    //Margin added when custom data input is visible
    @at-root {
      @include OnIE() {
        .u-custom-select__field {
          min-width: 166px;
        }
      }

      .equote_home-page-page {
        .equote__search-filters-form-custom-date
          ~ .equote__search-filters-form-nights {
          margin-top: 27px;
        }
      }
    }
  }

  .equote__search-filters-form-promo-checkboxes {
    @include OnIE() {
      width: 100%;
    }
  }

  .equote__search-filters-form-promo-checkboxes-group {
    display: block;
    padding: 0 40px 0 0;

    @include OnIE() {
      width: 100%;
    }
  }

  .equote__search-filters-form-promo-modal-link {
    color: $tri-brand-blue-two;
    text-decoration: underline;
    font-size: 14px;
    margin-left: 8px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      color: $anchor-hover;
    }
  }

  .equote__search-filters-form-promo-checkbox {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0 0 $col-gutter - 10 0;
    width: 100%;
    flex: 0 0 100%;
    &.disabled {
      opacity: 0.75;
    }
    button {
      margin: 0 $col-gutter - 5 0 0;
      min-width: 18px;
      min-height: 18px;
    }
  }

  .equote__search-filters-toggle-date {
    color: $tri-brand-blue-two;
    cursor: pointer;
    padding: $col-gutter - 15 0;
    text-decoration: underline;
    transition: background-color 0.25s linear;

    &:hover {
      color: darken($tri-brand-blue-two, 20%);
    }
  }
}
