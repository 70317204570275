//   ========================================================================
//   Mixins
//   ========================================================================

//Background image
@mixin bg-image($bgAttachment, $bgColor, $bgSize, $bgRepeat, $bgPosition) {
  background-attachment: $bgAttachment;
  background-color: $bgColor;
  background-size: $bgSize;
  background-repeat: $bgRepeat;
  background-position: $bgPosition;
}

// Transitions
@mixin transition($type: all, $time: 0.25s, $effect: ease-in-out) {
  -webkit-transition: $type $time $effect;
  -moz-transition: $type $time $effect;
  -o-transition: $type $time $effect;
  -ms-transition: $type $time $effect;
  transition: $type $time $effect;
}

//Built limited width container
@mixin limit-content-width(
  $display_type,
  $margin_anchor,
  $max_width,
  $text_anchor,
  $width_percentage
) {
  display: $display_type;
  margin: $margin_anchor;
  max-width: $max_width;
  text-align: $text_anchor;
  width: $width_percentage;
}

//Offset horizontal and vertical axis
@mixin align-axis($x_offset, $y_offset) {
  -moz-transform: translate($x_offset, $y_offset);
  -ms-transform: translate($x_offset, $y_offset);
  -o-transform: translate($x_offset, $y_offset);
  -webkit-transform: translate($x_offset, $y_offset);
  transform: translate($x_offset, $y_offset);
}

//Offset vertical axis
@mixin align-y-axis($y_offset) {
  -moz-transform: translateY($y_offset);
  -ms-transform: translateY($y_offset);
  -o-transform: translateY($y_offset);
  -webkit-transform: translateY($y_offset);
  transform: translateY($y_offset);
}

//Offset horizontal axis
@mixin align-x-axis($x_offset) {
  -moz-transform: translateX($x_offset);
  -ms-transform: translateX($x_offset);
  -o-transform: translateX($x_offset);
  -webkit-transform: translateX($x_offset);
  transform: translateX($x_offset);
}

//Border Box
@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//Appearance override
@mixin appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

//Remove copy & CheckBoxInput
@mixin remove-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//Media Queries
@mixin MQ($canvas) {
  // breakpoints

  @if $canvas == xs {
    //xs
    @media only screen and ($bp-min-xs) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-min-sm) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-min-md) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-min-lg) {
      @content;
    }
  } @else if $canvas == lgr {
    //lgr
    @media only screen and ($bp-min-lgr) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-min-xl) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-min-xlr) {
      @content;
    }
  } @else if $canvas == xxl {
    //xxl
    @media only screen and ($bp-min-xxl) {
      @content;
    }
  }
}

//Conditionally Target IE10 and 11
@mixin OnIE($canvas: null) {
  @if $canvas == null {
    //Default without media query
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == xs {
    //xs
    @media only screen and ($bp-min-xs) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == sm {
    //sm
    @media only screen and ($bp-min-sm) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == md {
    //md
    @media only screen and ($bp-min-md) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == lg {
    //lg
    @media only screen and ($bp-min-lg) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == lgr {
    //lg
    @media only screen and ($bp-min-lgr) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == xlr {
    //xlr
    @media only screen and ($bp-min-xlr) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  } @else if $canvas == xl {
    //xl
    @media only screen and ($bp-min-xl) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
}
