@import '../configuration/config';

//Modal & Fade Backdrop
.u-fade {
  background-color: rgba(88, 88, 88, 0.75);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 21;

  @include OnIE() {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
}

.u-modal {
  background: $white;
  border: 1px solid $grey;
  left: $col-gutter;
  outline: none;
  overflow: hidden;
  position: absolute;
  right: $col-gutter;
  top: $col-gutter;

  &--preview-package {
    position: relative;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 3px 3px 10px rgba($color: $black, $alpha: 0.7);

    @include OnIE() {
      height: auto;
      top: 0;
      transform: translateX(-50%) translateY(0%);
    }

    .equote__package-detail-modal {
      max-height: 100%;

      @include OnIE() {
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
          display: none; // Safari and Chrome
        }
      }
    }

    .u-modal__body {
      overflow-y: scroll;
      background: $white;
    }
  }

  @media screen and ($bp-min-sm) {
    &.u-modal__xs {
      @include align-x-axis(-50%);
      left: 50%;
      max-width: 465px;
      right: auto;
      top: 40px;
      width: 100%;
    }

    &.u-modal__sm {
      @include align-x-axis(-50%);
      left: 50%;
      max-width: 480px;
      right: auto;
      top: 40px;
      width: 100%;
    }

    &.u-modal__md {
      @include align-x-axis(-50%);
      left: 50%;
      max-width: 780px;
      right: auto;
      top: 40px;
      width: 100%;
    }
  }

  @media screen and ($bp-min-lg) {
    @include align-x-axis(-50%);
    left: 50%;
    max-width: 1040px;
    right: auto;
    top: 40px;
    width: 100%;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $col-gutter + 10 $col-gutter + 10 0 $col-gutter + 10;
    position: relative;
    width: 100%;

    .btn {
      padding: $col-gutter - 11 $col-gutter;
    }
  }

  &__close {
    @include appearance-none;
    @include align-y-axis(-50%);
    align-items: center;
    color: $tri-brand-blue-one;
    cursor: pointer;
    display: flex;
    font-family: $sans-serif-stack;
    font-size: $font-size-base + 6;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__close-icon {
    width: 21px;
    margin: 0 0 0 2px;

    &--large {
      width: 28px;
    }
  }

  &__body {
    padding: $col-gutter;
    width: 100%;
    @media screen and ($bp-min-xl) {
      padding: $col-gutter + 10;
    }
  }
  &.equote__terms_modal {
    .u-modal__header {
      h2 {
        margin-top: 50px;

        @include MQ(sm) {
          margin-top: 0;
        }
      }
      .u-modal__close {
        position: absolute;
        right: 20px;

        @include MQ(sm) {
          position: relative;
          right: 0;
        }
      }
    }
  }
}

.virtual-tour-modal {
  background-color: #fff;
  width: 100%;
  max-width: 1600px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid;
  .virtual-tour-modal-content {
    padding: 0 20px 20px;
    .virtual-tour-modal-header {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;

      @include MQ(lg) {
        flex-direction: row;
        align-items: center;
        padding: 16px 34px;
      }

      .virtual-tour-modal-title-container {
        display: flex;
        align-items: center;

        .virtual-tour-modal-title {
          margin: 0;
          font-size: 23px;
        }
        img {
          margin-right: 32px;
        }
      }
      button.btn {
        appearance: none;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 21px;
        align-self: flex-end;
      }
    }
  }

  .virtual-tour-frame-container {
    position: relative;
    width: 100%;
    padding-bottom: 124%;

    @include MQ(lg) {
      padding-bottom: 56%;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
