@import '../configuration/config';

.equote-agent-footer {
  padding: 0;

  @include MQ(md) {
    padding: 64px 0;
  }

  &--contacts {
    display: flex;
  }

  &--accolades {
    margin: 0 20px;

    @include MQ(md) {
      margin: 0 64px;
    }

    @include MQ(lg) {
      margin: 0;
    }
  }

  &--header {
    text-align: center;
    margin-bottom: 64px;

    &-buttons {
      display: none;
    }
    &-buttons-wrapper {
      position: absolute;
      top: 100.1%;
      width: 100%;

      @include MQ(md) {
        position: relative;
        width: auto;
        display: none;
      }
      .btn {
        white-space: nowrap;
        font-size: 0.85em !important;
        @include MQ(md) {
          font-size: 1em;
        }
      }

      .btn-agent-info {
        flex: 1;

        @include MQ(md) {
          display: none;
        }
      }
      .btn-more-info {
        flex: 2;

        @include MQ(md) {
          flex: none;
        }
      }
    }

    p {
      padding: 0 0 14px 0;
      border-bottom: 1px solid $grey;
      margin: 0 30px;

      @include MQ(md) {
        border-bottom: 0;
        padding: 0 0 64px 0;
      }
    }
  }

  &--accolades-wrapper {
    max-width: 1440px;
    margin: auto;
    padding: 64px 0;
  }

  .equote-agent-details-banner {
    border: 0;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;

    &-bottom {
      @include MQ(md) {
        display: none;
      }
    }
    &--inner {
      @include MQ(md) {
        display: none;
      }
    }
    &--contacts {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      border-top: 1px solid $grey;
      padding: 10px 20px;

      .item {
        width: 50%;
        margin: 0;
        padding: 10px 0;

        &--title {
          font-family: $sans-serif-stack-bold !important;
        }

        p {
          padding: 0 10px 0 0;
          margin: -10px 0 0 0;
          text-align: left;
          border: 0;
          line-height: 30px;
          font-size: 0.85em;
          word-break: break-all;
        }
      }
    }
    &--button {
      display: none;
    }
    &.show {
      max-height: 450px;

      @include MQ(md) {
        display: none;
      }
    }
  }

  .equote-agent-footer--inner {
    justify-content: space-between;
    max-width: 900px;
    margin: auto;
    flex-direction: column;
    display: none;

    @include MQ(md) {
      flex-direction: row;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .equote-agent-footer-agency-logo {
      max-width: 160px;
      margin-right: 20px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include MQ(lg) {
        margin-right: 60px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .item {
      margin: 8px 0;
      color: $dark-grey;
      font-weight: 400;
      a {
        color: $brand-base-royal;
      }
      &--title {
        margin-right: 8px;
        text-transform: uppercase;
        font-size: 16px;
        color: $dark-grey;
      }
      &--info {
        font-size: 16px;
      }
    }
  }

  &--names {
    padding-right: 60px;
  }
}
