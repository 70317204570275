@import '../configuration/config';

.equote__agent-edit-info {
  &-spinner {
    display: block;

    @include MQ(md) {
      margin: 15px auto 0 auto;
    }
  }

  &-group-input {
    width: 100%;
    @include MQ(md) {
      width: 48%;
    }
  }

  &-input {
    font-family: $sans-serif-stack;
    padding: 8px 0;
    width: 100%;

    input {
      width: 100%;
    }

    label:nth-of-type(2) {
      font-size: 13px;
      color: $medium-grey;
      text-overflow: ellipsis;
      overflow: hidden;

      @include MQ(md) {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  &-message {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: $col-gutter - 10 0 0 0;
    padding: $col-gutter - 5;
    width: 100%;
  }

  &-message p {
    font-size: $font-size-base - 4;
    line-height: 1;
    margin: 0 0 $col-gutter - 15 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
      margin-right: 10px;
    }
  }

  &-message button {
    @include appearance-none();
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: $tri-brand-blue-one;
    cursor: pointer;
    font-size: $font-size-base - 5;
    text-decoration: underline;
    text-underline-position: under;
    width: 100%;
  }

  .u-modal__close {
    transform: none;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
  }

  .u-modal__header {
    display: flex;
    justify-content: center;
  }

  .u-modal__body {
    padding: 20px 30px 30px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-button {
    width: 100%;

    .btn {
      padding: 15px 60px;
      display: block;
      margin: 30px auto 10px auto;

      @include MQ(sm) {
        padding: 15px 90px;
      }
    }
  }
}
