.equote__terms_modal {
  p,
  li,
  a {
    font-family: Lato;
    font-size: 16px;
    line-height: 1.5;
  }

  table.privacy-policy-table {
    border-collapse: collapse;
    tbody {
      tr,
      th,
      td {
        border: 1px solid #ccc;
      }
    }
  }
  .privacy__top-spacing {
    margin-top: 32px;
  }
}
