@import '../configuration/config';

.equote__package-details-footer {
  background-color: $white;
  font-size: $font-size-base - 1;

  @include MQ(md) {
    border-top: $border-grey;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 20px;
    font-weight: 200;
  }

  &-rights {
    align-items: center;
    display: flex;
    color: $medium-grey;
    margin-bottom: 0;

    @include MQ(md) {
      color: $dark-grey;
      text-align: left;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-left: auto;
    a.btn {
      padding: 15px 0;
      color: $dark-grey;
      text-decoration: none;
      text-align: center;

      @include MQ(md) {
        padding: 15px 20px;
        text-align: left;
      }
    }
  }

  &-link.btn {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    @include MQ(md) {
      text-align: left;
    }

    @include MQ(xl) {
      &:after {
        content: '|';
        position: absolute;
        right: 0;
        top: 50%;
        @include align-y-axis(-50%);
      }
    }

    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }
}
