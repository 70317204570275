@import '../configuration/config';

.u-datepicker__today {
  position: relative;
  border: 2px solid $grey !important;
  text-transform: uppercase;
  color: $tri-brand-blue-two !important;
  background-color: $light-grey !important;

  @include MQ(lg) {
    position: absolute;
    left: 0;
  }

  &:hover {
    color: $white !important;
    background-color: $tri-brand-blue-one !important;
    border: 2px solid $white !important;
  }
}

.u-datepicker__years {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 5px 0;

  @include MQ(lg) {
    margin: 0 90px;
  }

  button {
    @include appearance-none;
    border: none;
    color: $medium-dark-grey;
    cursor: pointer;
    font-size: $font-size-base;
    padding: $col-gutter - 10;
    margin: 0 $col-gutter - 15;
    transition: 0.3s all;

    &.active-year {
      background-color: $grey;
    }
    &:hover {
      color: $white;
      background-color: $tri-brand-blue-one;
    }
  }
}

.CalendarMonth {
  padding: 0 !important;

  @include MQ(md) {
    padding: 0px 13px !important;
  }
}

.DayPicker {
  font-family: $sans-serif-stack;
  margin: 0 auto 0;

  strong {
    color: $medium-dark-grey;
    font-weight: normal;
    text-transform: uppercase;
  }

  .CalendarMonth_caption {
    padding-bottom: 50px;
  }

  .DayPickerNavigation_button {
    position: absolute;
    top: 18px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;

    &:first-of-type {
      left: 10px;

      @include MQ(md) {
        left: 0;
      }
    }

    &:last-of-type {
      right: 30px;

      @include MQ(md) {
        right: 0;
      }
    }
  }

  .DayPicker_weekHeader {
    padding: 0px !important;
    @include MQ(md) {
      padding: 0px 13px !important;
    }
  }

  .DayPicker_weekHeader_li {
    small {
      color: $medium-grey;
      font-size: $font-size-base - 3;
    }
  }

  .CalendarDay {
    font-size: $font-size-base;

    &.CalendarDay__default {
      border: 1px solid #e5e5e5;
      color: $tri-brand-blue-two;
      padding: 0;

      &:hover {
        background: $tri-brand-blue-two;
        border: 1px double $tri-brand-blue-two;
        color: $white;
      }
    }

    &.CalendarDay__blocked_calendar,
    &.CalendarDay__blocked_calendar:active,
    &.CalendarDay__blocked_calendar:hover {
      background: $lighter-grey;
      color: $medium-grey;
    }

    &.CalendarDay__blocked_out_of_range,
    &.CalendarDay__blocked_out_of_range:active,
    &.CalendarDay__blocked_out_of_range:hover {
      background: $white;
      border: 1px solid $grey;
      color: $grey;
    }

    &.CalendarDay__selected,
    &.CalendarDay__selected:active,
    &.CalendarDay__selected:hover {
      background: $tri-brand-blue-one;
      border: 1px double $tri-brand-blue-one;
      color: $white;

      > div {
        background: $tri-brand-blue-one;
        border: 1px double $tri-brand-blue-one;
        color: $white;
      }
    }

    &.CalendarDay__selected_span,
    &.CalendarDay__hovered_span {
      background: $tri-brand-blue-two;
      border: 1px double $tri-brand-blue-two;
      color: $white;

      > div {
        background: $tri-brand-blue-two;
        border: 1px double $tri-brand-blue-two;
        color: $white;
      }
    }
  }
}
