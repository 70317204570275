@import '../configuration/config';

.equote__package-details-agent-footer--accolades {
  .equote-agent-accolade {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;

    &-text {
      font-family: $sans-serif-stack-light;
      font-size: 1.25em;
      font-weight: 100;
      color: $white;
      text-align: center;
      padding: 30px 20px;
    }
    &--blue {
      background-color: $royal-blue-banner;
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include MQ(xlr) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 30px 30px;
        margin: 0 20px;
      }

      @include OnIE(xlr) {
        display: flex;
      }

      .accolade__detail-icon {
        max-width: 70px;
        min-width: 55px;
        width: 100%;
      }

      .accolade__banner-text {
        font-size: 1.25em;
        font-family: $sans-serif-stack-light;
        color: $white;
        margin: 30px 20px;
        text-align: center;
        flex: 1;
      }

      .accolade__detail {
        margin: 20px 0;

        @include MQ(md) {
          margin: 0;
        }
      }

      .accolade__detail-text {
        margin: 0 -30px !important;
      }
      .accolade__detail-text h3 {
        margin-top: 3px;
        font-size: 0.56em !important;
      }
      .accolade__detail-text h1 {
        font-size: 1em !important;
        font-weight: 900;

        @include MQ(md) {
          font-size: 1.2em !important;
        }

        @include MQ(lg) {
          font-size: 1.5em !important;
        }
      }
    }
    &--black {
      background-color: $charcol;
      padding: 30px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include MQ(lg) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }

      .accolade__detail {
        margin: 20px 0;

        @include MQ(lg) {
          margin: 0;
        }
      }

      .accolade__detail-icon {
        width: 100%;
      }
      .accolade__detail-text {
        margin: 0 !important;

        @include MQ(md) {
          margin: 0 !important;
        }
      }
      .accolade__detail-text h3 {
        font-size: 0.56em !important;
      }
      .accolade__detail-text h1 {
        font-size: 1.625em !important;
      }
    }
  }

  .accolade__detail {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 40px;

    &-icon {
      max-height: 90px;

      @include OnIE() {
        max-height: auto;
        height: 100%;
      }
    }

    &-icon-container {
      min-width: 40px;
    }
    &-text {
      white-space: nowrap;
    }

    &-text p {
      font-size: 12px;
      margin: 6px;
      font-style: italic;
      color: $white;
      font-family: $sans-serif-stack-light;
    }

    &-text h2,
    h3 {
      font-size: 15px;
      margin-bottom: 6px;
      font-family: $sans-serif-stack-light;
      color: $white;
      font-weight: 100;
      letter-spacing: 5px;
    }

    &-text h1 {
      display: flex;
      font-size: 1.375em;
      margin-top: 6px;
      margin-bottom: 0;
      font-family: $royal-header-font;
      color: $white;
      text-align: center;
      justify-content: center;

      @include MQ(md) {
        font-size: 2em;
      }
    }

    &-text h1 span {
      font-size: 0.54em;
      display: inline-flex;
      flex-direction: column;
      margin: 0 3px;
    }

    &-text h1 span small {
      font-family: $royal-header-font;
    }
  }

  .accolade__columns {
    background-color: $azamara-green-banner;
    padding: 60px;

    @include MQ(md) {
      padding: 30px;
    }

    @include MQ(lg) {
      padding: 60px;
    }

    &--wrapper {
      display: block;

      @include MQ(md) {
        display: flex;
      }
    }
    &--column {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px 10px;
      flex: 1;
      position: relative;

      @include MQ(md) {
        padding: 20px 20px;
      }
      @include MQ(lg) {
        padding: 20px 60px;
      }
    }

    &--column p {
      text-align: center;
      margin-top: 15px;

      @include OnIE() {
        width: 100%;
        white-space: normal;
      }
    }

    &--circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      background-color: $azamara-blue;
      border-radius: 50%;

      &::before {
        content: '';
        background-color: $azamara-blue;
        width: 134px;
        height: 134px;
        border-radius: 50%;
        position: absolute;
        z-index: 2;

        @include OnIE() {
          position: relative;
        }
      }

      &::after {
        content: '';
        background-color: $azamara-green-banner;
        width: 138px;
        height: 138px;
        border-radius: 50%;
        position: absolute;

        @include OnIE() {
          position: relative;
        }
      }
    }
    &--circle-title {
      position: absolute;
      z-index: 3;
      color: $azamara-green-banner !important;
      text-align: center;
      font-family: $sans-serif-stack !important;
      font-size: 16px !important;

      @include OnIE() {
        position: relative;
        top: 0px;
      }

      img {
        max-width: 15px;
        display: block;
        margin: 0 auto;
        padding-top: 10px;

        @include OnIE() {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  &-wrapper {
    max-width: 1440px;
    margin: auto;
    padding: 64px 0;
  }
}
