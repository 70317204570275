@import '../configuration/config';

.equote__search-featured-destinations {
  display: flex;
  flex-direction: column;

  @include OnIE() {
    margin-top: -160px;
  }

  @include MQ(md) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .equote__search-featured-destination {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    cursor: pointer;

    @include OnIE() {
      margin-top: 180px;
    }

    @include MQ(md) {
      padding: 0 35px;
      width: 50%;
    }

    @include MQ(xl) {
      width: 20%;
      &:first-child {
        margin-left: -15px;
      }

      &:last-child {
        margin-right: -15px;
      }
    }

    .equote__search-featured-destination-image {
      height: 0;
      padding-bottom: 55%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 15px;

      @include OnIE() {
        display: block;
      }

      @media screen and ($bp-min-xl) {
        padding-bottom: 80%;
      }
    }

    .equote__search-featured-destination-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      letter-spacing: 0.05em;

      @include OnIE() {
        display: block;
      }

      h6 {
        font-size: 27px;
        font-weight: 400;
        line-height: 32px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: $medium-dark-grey;
        flex: 1;
        margin-bottom: 10px;
        line-height: 18px;

        @include OnIE() {
          display: block;
        }
      }
    }

    .equote__search-featured-destination-btn {
      display: flex;
      font-size: $font-size-small;
      text-decoration: underline;
      color: $tri-brand-blue-two;
      font-family: $sans-serif-stack;
      line-height: 27px;
      cursor: pointer;

      @include OnIE() {
        align-items: center;
      }

      img {
        width: 12px;
        @include OnIE() {
          width: 12px;
          height: 100%;
        }
      }
    }
  }
}

.equote__search-basic-destinations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include OnIE() {
    margin-top: 160px;
  }

  &-title {
    width: 100%;
    display: flex;
    cursor: default;

    h6 {
      margin-left: auto;
      margin-right: auto;
      line-height: 52px;
      letter-spacing: 0.05em;
    }
  }
  .equote__search-basic-destination {
    width: 100%;
    text-align: left;
    color: $tri-brand-blue-one;
    background-color: $light-grey;

    &:nth-child(even) {
      background-color: white;
    }

    .equote__search-basic-destination-button-inner {
      display: flex;
      justify-content: space-between;
      line-height: 35px;
      letter-spacing: 0.05em;

      @include OnIE() {
        align-items: center;
      }

      img {
        width: 12px;

        @include OnIE() {
          height: 100%;
        }
      }
    }

    @media screen and ($bp-min-xl) {
      width: 20%;
      border: 1px solid $grey;
    }
  }

  @media screen and ($bp-min-xl) {
    flex-direction: row;
  }
}
