@import '../configuration/config';

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  scroll-behavior: smooth;

  @include OnIE {
    height: 100%;
  }
}

html {
  &.ReactModal__Html--open {
    overflow: hidden;
  }
}
body {
  margin: 0;
  padding: 0;

  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

.u-text-center {
  text-align: center;
}
.modal-opened {
  overflow: hidden;
  height: 100%;
  position: relative;
}
.equote__generate-offers {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  color: $font-color-base;
  font: $font-base;
  padding: 0;
  display: flex;
  margin: 0 auto 0;
  max-width: $app-max-width;
  position: relative;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;

  .spinnerCircles {
    position: relative;

    img {
      display: none;

      @include OnIE {
        display: block;
      }
    }
    object {
      @include OnIE {
        display: none;
      }
    }

    &:after {
      content: '';
      position: relative;
      display: block;
    }
    img,
    iframe,
    object {
      animation: spinner 2.5s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
      width: 4em;
      height: 4em;
      background-color: transparent;
      border: none;
    }
    &:after {
      animation: shadow 2.5s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
      bottom: -0.5em;
      height: 0.25em;
      border-radius: 50%;
      background-color: rgba(#000, 0.2);
    }

    @-webkit-keyframes spinner {
      0% {
        transform: scale(1) rotate(0deg);
      }
      50% {
        border-radius: 50%;
        transform: scale(0.5) rotate(360deg);
      }
      100% {
        transform: scale(1) rotate(720deg);
      }
    }

    @keyframes spinner {
      0% {
        transform: scale(1) rotate(0deg);
      }
      50% {
        border-radius: 50%;
        transform: scale(0.5) rotate(360deg);
      }
      100% {
        transform: scale(1) rotate(720deg);
      }
    }
    @-webkit-keyframes shadow {
      0% {
        transform: scale(1);
        background-color: rgba(#000, 0.1);
      }

      50% {
        transform: scale(0.5);
        background-color: rgba(#000, 0.1);
      }
      100% {
        transform: scale(1);
        background-color: rgba(#000, 0.1);
      }
    }
    @keyframes shadow {
      0% {
        transform: scale(1);
        background-color: rgba(#000, 0.1);
      }

      50% {
        transform: scale(0.5);
        background-color: rgba(#000, 0.1);
      }
      100% {
        transform: scale(1);
        background-color: rgba(#000, 0.1);
      }
    }
  }

  .equote_email-preview {
    .u-page-header {
      padding: 20px 10px 0 10px;
      @include MQ(md) {
        padding: 50px 0;
      }
    }
  }
  .equote_search-by-price {
    .u-content-container {
      .equote__search-price-table--filters-row {
        &-group {
          width: 100%;
          @include MQ(md) {
            width: auto;
          }
        }
        &:nth-child(2) {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;

          @include MQ(md) {
            margin-top: 0px;
          }
          .equote__search-filters-form-field-input {
            width: 100%;

            @include MQ(md) {
              width: 142px;
            }
            select {
              @include MQ(md) {
                margin: 0;
              }
            }
            span {
              @include MQ(md) {
                right: 12px;
              }
            }
          }
        }
      }
    }
  }
}

#mid-width-alt {
  max-width: $content-max-width-outline;
  width: 100%;
  margin: auto;
}

#root {
  max-width: $app-max-width;
  width: 100%;
  margin: auto;
}

//Remove outline focus in all elements
*:focus {
  outline: none;
}

@at-root {
  // The browser will zoom if the font-size is less than 16px, so and the default
  // font-size for form elements should be 16px.

  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select,
  textarea {
    font-size: $font-size-base - 2 !important;

    @include MQ(md) {
      font-size: auto;
    }
  }

  //Hide default arrow on select tag IE 11
  select {
    &::-ms-expand {
      display: none;
    }
  }
}
