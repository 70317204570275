@import '../configuration/config';

.equote__header {
  background-color: $tri-brand-blue-one;

  .equote__header-container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 $col-gutter;
    @media screen and ($bp-min-lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .equote__header_user_logo_container {
    cursor: pointer;
    display: flex;

    @include OnIE() {
      flex: auto;
      height: 100%;
    }
    @include OnIE(lg) {
      height: auto;
    }
  }

  .equote__agent-icon {
    border: 1px solid $white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    @include MQ(sm) {
      margin: 0 $col-gutter - 5;
    }
    @include MQ(md) {
      margin: 0 $col-gutter - 5 0 0;
    }
  }

  .equote__agent-toggle {
    align-items: center;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100px;
    padding: 0 $col-gutter;
    text-align: center;
    margin: 5px 0;

    @include MQ(md) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      text-align: left;
      margin: 0px;
    }
  }

  .equote__header-logo {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 100px;
    padding: 0;

    @include MQ(xs) {
      padding: 0 $col-gutter;
    }

    button {
      appearance: none;
      border: 0;
      background-color: transparent;

      img {
        cursor: pointer;
      }
    }
  }

  .equote__header-brands {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    min-height: 100px;
    border-bottom: $border-medium;

    @include MQ(lg) {
      border-bottom: none;
    }

    @include OnIE() {
      justify-content: space-around;
      min-width: 510px;
      min-height: auto;
      padding: 35px;
    }
    @include OnIE(lg) {
      padding: 0px;
    }
  }

  .equote__header-brand {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 170px;
    padding: 0 $col-gutter / 2;
    width: 45%;
    position: relative;

    img {
      @include OnIE() {
        display: block;
        position: absolute;
        min-width: 100px;
        max-width: 120px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @media screen and ($bp-min-lg) {
      min-height: 100px;
      padding: 0 $col-gutter;
    }
  }
}
