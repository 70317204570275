@import '../configuration/config';

.equote__package-details-navigation {
  margin-top: 64px;
  display: flex;
  border-top: $border-grey;
  border-bottom: $border-grey;
  box-shadow: 0;
  flex-wrap: nowrap;
  position: sticky;
  top: 0;
  z-index: 12;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;

  @include MQ(md) {
    display: flex;
    flex-wrap: wrap;
    border-top: $border-grey;
    border-bottom: $border-grey;
    border-left: $border-grey;
    box-shadow: 0 10px 13px -10px #c4c4c4;
  }

  &--button.btn {
    flex: 1;
    border-right: 0;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    font-size: 0.8em !important;
    line-height: 17px !important;

    @include MQ(md) {
      flex: 1;
      letter-spacing: 0.05em;
      border-right: $border-grey;
      font-size: 1em !important;
      line-height: 29px !important;
    }
  }

  @at-root {
    .royal {
      .equote__package-details-navigation--button.btn.active {
        color: $royal-highlight;
        background-color: $royal-blue;
      }
    }
    .celebrity {
      .equote__package-details-navigation--button.btn.active {
        color: white;
        background-color: $celebrity-blue;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
