@import '../configuration/config';

.equote__package-details-itinerary {
  padding: 8px 0;

  @include MQ(md) {
    padding: 64px 0;
  }

  &--header {
    padding: 0 0 32px;

    @include MQ(md) {
      padding: 32px 48px;
    }

    @include MQ(lg) {
      padding: 0 0 32px;
    }

    h1.section-title {
      padding: 25px 0 0 !important;
      margin: 0 20px;
      text-align: center;

      @include MQ(md) {
        padding: 64px 0 0 !important;
        margin: 0 32px;
        text-align: left;
      }
    }

    &--links {
      display: block;
      padding: 0;
      text-align: center;

      @include MQ(md) {
        display: flex;
        text-align: left;
        padding: 32px 32px 0 32px;
      }
    }

    &--links--group {
      display: flex;
      margin-top: 10px;
      justify-content: center;

      .divisor {
        padding: 0 10px;
      }

      @include MQ(md) {
        margin: 0;
      }
    }

    &--links--wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;

      @include MQ(md) {
        flex-direction: row;
      }
    }

    &--links--detail {
      white-space: nowrap;
      margin: 15px 0;
      font-size: 15px;
      letter-spacing: 0.05em;
      color: $medium-dark-grey;

      @include MQ(md) {
        margin: 0 40px 0 0;
      }
    }

    &--link {
      display: inline;
      cursor: pointer;
      color: $brand-base-royal;
      border-bottom: 2px solid $brand-base-royal;
      font-weight: 500;
      position: relative;

      img {
        position: absolute;
        width: 50px;
        left: -40px;
      }
    }
  }

  p {
    margin: 0;
  }
  .slick-slider {
    overflow: hidden;
    padding: 0 64px 0 20px;

    @include MQ(md) {
      padding: 0 64px;
    }
  }
  .slick-list {
    overflow: visible;
    width: 108%;

    @include MQ(md) {
      width: 95%;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-prev {
    left: 4px;

    @include MQ(md) {
      left: 22px;
    }
    &:before {
      display: none;
    }
  }
  .slick-next {
    right: 22px;
    &:before {
      display: none;
    }
  }
  .slick-arrow {
    z-index: 10;
    width: 32px;
    height: 32px;
    &.hide {
      display: none;
    }
  }
  .slick-slide {
    margin: 0;
    border-bottom: 1px solid $bd-grey;
    display: flex;
    height: auto;
    transition: all 0.2s ease;
    overflow: hidden;

    @include MQ(md) {
      margin: 0 14px;
    }
    &:not(.slick-active) {
      opacity: 0.5;
      transform: scale(0.9);
    }
    > div {
      width: 100%;
      flex: 1;
    }
  }
  &--filter {
    display: inline-flex;
    color: $medium-grey;
    font-weight: 400;

    &--modal {
      display: inline-block;
      margin-left: auto;
      position: relative;

      @include MQ(md) {
        float: right;
      }

      &--option {
      }
    }

    &--option {
      cursor: pointer;
      p {
        margin: 0 15px;
        color: $brand-base-royal;
        border-bottom: 2px solid $brand-base-royal;
        font-weight: 500;
      }
    }
  }

  &-day {
    &--header {
      background-color: $royal-blue;
      padding: 8px 20px;
      color: white;
      position: relative;
      p {
        margin: 0;
      }
      &--cruise-tour-flag {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $royal-bright;
        padding: 8px;
        p {
          color: white !important;
          font-size: 1em;
          margin: 0;
        }
      }

      &--day-text {
        font-size: 1.3em;
        text-transform: uppercase;
      }
    }
    &--sub-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $light-blue;
      padding: 1px;
      min-height: 48px;

      @include MQ(sm) {
        padding: 8px 20px;
      }

      &-arrival {
        text-transform: uppercase;
        margin: 0 0 0 8px !important;
        font-size: 10px !important;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        color: #005edc !important;
        flex-direction: column;

        @include MQ(xs) {
          flex-direction: row;
          min-width: 113px;
        }

        @include MQ(sm) {
          font-size: 13px !important;
        }

        span {
          margin: 0 5px 0 2px;
          color: $medium-grey;
          white-space: nowrap;

          @include MQ(sm) {
            margin: 0 5px;
          }
        }
      }
    }
    &--image-container {
      width: 100%;
      padding-bottom: 80%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #eee;
    }
    &--description {
      &--title {
        font-size: 0.8em;
        line-height: 23px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $tri-brand-blue-one;
        margin: 10px 10px !important;
        white-space: pre-wrap;
        word-break: break-word;
        @include MQ(md) {
          margin: 10px 20px !important;
          letter-spacing: 0.05em;
          line-height: 28px;
        }
      }
      &--description {
        line-height: 22px;
        color: $medium-grey;
        padding: 0 10px 32px;
        letter-spacing: 0.05em;

        @include MQ(md) {
          line-height: 28px;
          color: $medium-grey;
          padding: 0 20px;
        }
      }
    }
  }
}

@at-root {
  .royal {
    .equote__package-details-itinerary {
      .equote__package-details-itinerary-day--header {
        background-color: $royal-blue;
        .equote__package-details-itinerary-day--header--day-text,
        .equote__package-details-itinerary-day--header--date {
          color: white;
        }
        .equote__package-details-itinerary-day--header--date {
          font-size: 1em;
          color: white;

          @include MQ(md) {
            font-size: 1.125em;
          }
        }
      }
      .equote__package-details-itinerary-day--description {
        &--title {
          font-size: 19px;
          letter-spacing: 0.15em;
        }
      }
      &--description {
        line-height: 24px;
      }
    }
  }
  .celebrity {
    .equote__package-details-itinerary {
      .equote__package-details-itinerary-day--header {
        background-color: $celebrity-blue;
        .equote__package-details-itinerary-day--header--day-text,
        .equote__package-details-itinerary-day--header--date {
          color: white;
        }
      }
      .equote__package-details-itinerary-day--description {
        &--title {
          font-size: 19px;
          line-height: 34px;
        }
      }
    }
  }
}
