@import '../configuration/config';

.equote__search-filters-form-nights {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include MQ(lg) {
    margin-top: 63px;
  }
  @include MQ(xl) {
    margin-top: 48px;
  }

  label {
    &.u-custom-select__label {
      margin: -6px $col-gutter - 10 12px 0;
      width: auto;
      text-transform: none;
      color: $tri-brand-blue-one;
      font-size: 15px;
      font-weight: 400;
    }
  }
  input {
    padding: 0 !important;
    margin-top: -15px;
    height: 44px;

    @include MQ(md) {
      margin-top: -9px;
      height: auto;
      padding: 3px 0 12px 0 !important;
    }
  }
}
