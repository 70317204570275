@import '../configuration/config';

.equote__package-details-state-rooms {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 12px 24px;

  .content-disclaimer {
    max-width: 520px;
    margin: 32px auto;
    padding: 0 24px;
    display: block;
    text-align: center;
    font-size: 13px;
  }

  .pricing-disclaimer {
    max-width: 1200px;
    margin: 48px auto;
    display: block;
    font-size: 13px;
  }

  .equote__package-details-state-rooms--carousel-container {
    @include MQ(xlr) {
      display: none;
    }
    .equote__package-details-state-room {
      border: 1px solid $bd-grey;
      border-top: none;
    }
  }

  .equote__package-details-state-rooms--desktop-container {
    display: none;
    max-width: 1050px;
    margin: 0 auto;
    @include MQ(xlr) {
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 22px;
    }
  }

  .equote__package-details-state-room-wrapper {
    .state-room-disclaimers {
      text-align: center;
      p {
        margin: 8px 0;
        line-height: 1;
        color: $dark-grey;
        font-size: 13px;
      }
    }
    .state-room-taxes {
      text-align: right;
      margin: 0;
      font-size: 12px;
    }
    .equote__package-details-state-room {
      @include MQ(xlr) {
        display: flex;
        padding: 0;
      }

      .equote__package-details-state-room--image-container {
        position: relative;

        .equote__package-details-state-room--image-container-picture {
          width: 100%;
          display: block;
        }
      }

      .equote__package-details-state-room--body {
        width: 100%;
        @include MQ(xlr) {
          display: flex;
          flex: 1;
        }

        .equote__package-details-state-room--body--section {
          @include MQ(xlr) {
            width: 100%;
            display: flex;
            flex-direction: column;
          }

          .equote__package-details-state-room--body--section-details {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-bottom: 1px solid #c4c4c4;
            @include MQ(xlr) {
              border-right: 1px solid $bd-grey;
            }
          }
        }
      }

      .equote__package-details-state-room--header {
        background-color: $royal-blue;
        padding: 6px 6px 6px 30px;
        height: 49px;
        display: flex;

        .equote__package-details-state-room--header--title {
          height: 100%;
          display: flex;
          align-items: center;

          p {
            color: #fff;
            font-size: 18px;
          }
        }
        .details-state-room--image-container-virtual-tour-trigger {
          margin-left: auto;

          .u-virtual-tour-trigger-content {
            background-color: #fff;
            border-radius: 4px;
            padding: 2px 16px 2px 2px;
            align-items: center;
            .u-virtual-tour-image-wrapper {
              //padding: 2px 16px 2px 8px;
              height: 100%;
              background: #fff;
              img {
                width: 55px;
              }
            }
            .u-virtual-tour-trigger-text-container {
              background-color: #fff;
              padding-left: 0;

              margin-left: 0;
              p {
                color: #000;
              }
            }
          }
        }
        &.stateroom-desktop-only {
          display: none;
          @include MQ(xlr) {
            display: flex;
          }
        }

        &.stateroom-carousel-only {
          display: block;

          .equote__package-details-state-room--header--title {
            display: flex;
            justify-content: space-between;
            .u-virtual-tour-trigger {
              background-color: transparent;
            }
            .u-virtual-tour-trigger-content {
              .u-virtual-tour-image-wrapper {
                //padding: 8px 24px 8px 12px;
                background: transparent;
                img {
                  width: 60px;
                }
              }

              .u-virtual-tour-trigger-text-container {
                display: none;
              }
            }
          }

          @include MQ(xlr) {
            display: none;
          }
        }
      }

      .equote__package-details-state-room--pricing {
        .equote__package-details-state-room--pricing--prices {
          border-collapse: collapse;
          width: 100%;
          font-size: 15px;

          thead {
            background-color: #f5f5f5;

            tr td {
              padding: 8px 0;
            }
            td {
              &:last-child {
                border-right: none;
              }
            }

            .equote__package-details-state-room--pricing--header-non-refundable-flag {
              font-size: 12px;
              color: #fff;
              background-color: $tri-brand-blue-two;
              padding: 6px;
              float: right;
              border-radius: 2px;
              margin-right: 2px;
            }
          }

          tr {
            &:nth-child(3) td {
              border-bottom: none;
            }
            td {
              border-left: 1px solid $bd-grey;
              border-right: 1px solid $bd-grey;
              border-bottom: 1px solid $bd-grey;
              padding: 12px 0;

              &:first-child {
                padding-left: 30px;
                padding-right: 30px;
              }

              &:last-child {
                padding-left: 30px;
                @include MQ(xlr) {
                  padding-left: 56px;
                }
              }

              > * + * {
                padding-left: 12px;
              }
            }
          }

          .equote__package-details-state-room--pricing--prices-price {
            padding: 4px 0 4px 20px;

            .equote__package-details-state-room--pricing--prices-price--name {
              min-width: 80px;
            }

            .equote__package-details-state-room--pricing--prices-price--amount {
              border-right: none;
              strong {
                font-weight: 700;
                font-size: 18px;
                color: $royal-blue;
              }
            }

            .equote__package-details-state-room--pricing--prices-price--promo {
              color: $dark-grey;
              font-size: 13px;
            }
          }
        }
      }

      .equote__package-details-state-room--stats {
        display: flex;
        padding-left: 20px;
        border-top: 1px solid $bd-grey;

        .equote__package-details-state-room--stat-container {
          padding: 8px 32px 8px 0;

          &:not(:first-child) {
            padding-left: 16px;
          }

          &:not(:last-child) {
            border-right: 1px solid $bd-grey;
          }

          p {
            line-height: 1;

            &:last-child {
              color: $royal-blue;
              font-weight: 700;
            }
          }
        }
      }

      .equote-package-state-room--features-description {
        width: 100%;
        padding: 24px 24px 0 24px;
        border-right: none;
        border-bottom: none;
        background-color: #fff;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        flex: 1;

        &-print {
          display: none;
        }

        p {
          color: $medium-grey;
          line-height: 1.4 !important;
          margin: 0;
        }

        &.expanded {
          z-index: 1;

          @include MQ(xlr) {
            p {
              top: 16px;
              left: 16px;
              background-color: #fff;
            }
          }

          overflow: auto;
        }

        &--toggle-button {
          color: $tri-brand-blue-one;
          cursor: pointer;
        }

        &--disclaimer {
          font-size: 0.6em;
          padding-bottom: 5px;
        }
      }
    }
  }
  @at-root {
    .celebrity {
      .equote__package-details-state-rooms {
        .equote__package-details-state-room {
          .equote__package-details-state-room--header {
            background-color: $black;
          }

          .equote__package-details-state-room--pricing {
            height: 100%;

            thead {
              color: $black;
              .equote__package-details-state-room--pricing--header-non-refundable-flag {
                background-color: $black;
              }
            }
            .equote__package-details-state-room--pricing--prices-price--amount {
              strong {
                color: $black;
              }
            }
          }
        }
      }
    }

    .royal {
      .equote__package-details-state-rooms {
        .equote__package-details-state-room {
          .equote__package-details-state-room--header {
            background-color: $royal-blue;
          }
          .equote__package-details-state-room--pricing {
            .equote__package-details-state-room--pricing--header {
              .equote__package-details-state-room--pricing--header-title {
                color: $royal-blue;
                .equote__package-details-state-room--pricing--header-non-refundable-flag {
                  background-color: $royal-blue;
                }
              }
            }
          }
        }
      }
    }
  }
}
