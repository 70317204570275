@import '../configuration/config';

// Override the equote colors
$tri-brand-blue-one: #15264c;
$tri-brand-blue-two: #004275;
$tri-brand-blue-three: #00205b;

.equote__package-details-header {
  background-color: $tri-brand-blue-one;
  display: flex;
  height: 115px;

  @at-root {
    .celebrity {
      .equote-header {
        background-color: $celebrity-blue;
      }
    }
    .royal {
      .equote-header {
        background-color: $royal-blue;
      }
    }
  }

  &--inner {
    width: 100%;
    padding: 0 32px;
    min-height: 96px;
    &.img {
      height: 100%;
    }
  }

  &--equote-logo-container {
    padding: 0;

    @include MQ(sm) {
      padding: 20px;
    }

    margin-left: 10%;
    img {
      width: 100%;
    }
  }

  &--brand-logo-container {
    margin-left: auto;
    display: none;
    max-width: 156px;

    @include MQ(md) {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  &--agent-logo-container {
    cursor: pointer;
    background-color: $white;
    height: 101%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    @include MQ(md) {
      cursor: default;
    }

    img {
      max-width: 70px;
      max-height: 100px;

      @include MQ(xs) {
        width: auto;
        max-width: 160px;
        max-height: 100px;
        height: auto;
      }
    }
  }
}

//Royal Caribbean Cruise Equote
.royal {
  .equote__package-details-header {
    background-color: $tri-brand-blue-one;
  }
}
//Celebrity X Cruise Equote
.celebrity {
  .equote__package-details-header {
    background-color: $tri-brand-blue-two;
  }
}
