@import '../configuration/config';

.equote-agent-details-banner {
  margin-bottom: 30px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;

  @include MQ(md) {
    max-height: 450px;
    border-bottom: $border-light-grey;
    border-top: $border-light-grey;
  }

  &-show-info {
    @include MQ(md) {
      display: none !important;
    }
  }

  &.show {
    max-height: 450px;
    border-bottom: $border-light-grey;
    border-top: $border-light-grey;
  }

  &--button {
    margin: 20px auto;

    @include MQ(md) {
      margin: 0px auto;
    }
  }

  &--inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 32px 20px;

    @include MQ(md) {
      padding: 32px 64px;
    }

    @include MQ(lg) {
      padding: 32px 40px 32px 20px;
    }
  }

  &--contacts {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    letter-spacing: 0.05em;

    @include MQ(md) {
      align-items: center;
    }
  }

  &--contacts--button {
    height: 100%;
  }

  &-edit {
    margin: 20px auto;

    @include MQ(xl) {
      margin: 0 0 0 auto;
    }

    .btn {
      font-size: 13px;
      padding: 15px 25px;
      background-color: $white !important;
      text-transform: uppercase;

      &:hover {
        background-color: $light-grey !important;
      }
    }
  }

  &--contacts .item {
    width: 100%;
    margin: 0;
    padding: 10px 0;

    @include MQ(xs) {
      width: 50%;
    }

    @include MQ(md) {
      width: auto;
      margin: 0 16px;
    }

    &--title {
      line-height: 30px;
      font-family: $sans-serif-stack-bold !important;
      text-transform: uppercase;
      color: $tri-brand-blue-one;

      @include MQ(md) {
        text-align: left;
      }
    }
    &--info {
      line-height: 30px;
      padding: 18px 0px 0 0;

      @include MQ(md) {
        text-align: left;
      }
    }

    p {
      margin: -10px 0 0 0;
      border: 0;
      line-height: 25px;
      font-size: 0.85em;
      word-break: break-all;
    }

    &.royal,
    &.celebrity {
      color: $royal-blue;
      .item--title {
        font-family: $royal-primary-font;
      }
      .item--info p {
        font-family: $royal-primary-font;
        font-weight: $celebrity-font-weight-light;
      }
    }
  }
}
