@import '../configuration/config';

.btn {
  -moz-transition: background-color 0.25s linear;
  -ms-transition: background-color 0.25s linear;
  -o-transition: background-color 0.25s linear;
  -webkit-transition: background-color 0.25s linear;
  background-color: $white;
  border: none;
  color: $font-color-base;
  cursor: pointer;
  font-family: $sans-serif-stack;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: $button-letter-spacing;
  margin: 0;
  padding: 15px 20px;
  transition: background-color 0.25s linear;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &.not-allowed:disabled {
    cursor: not-allowed;
  }

  &.btn-primary {
    background-color: $tri-brand-blue-one;
    color: $white;

    &:hover {
      background: lighten(#0f6cae, 20%);
    }
  }

  &.btn-secondary {
    background-color: $medium-grey;
    color: $white;

    &:hover {
      background: darken($medium-grey, 20%);
    }
  }

  &.similar {
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 24px;
    font-family: $sans-serif-stack-light;
    color: #f5f5f5;
    background-color: $tri-brand-blue-two;
    border: 1px solid $tri-brand-blue-two;

    &:hover {
      background-color: $tri-brand-blue-one;
    }
    &:disabled {
      background-color: $tri-brand-blue-two;
    }
  }

  &.btn-info {
    line-height: 24px;
    letter-spacing: 0.15em;
    font-family: $sans-serif-stack-light;
    font-weight: 700;
    color: $black;
    background-color: $grey;
  }

  &.btn-tertiary {
    background-color: $grey;
    color: $black;

    &:hover {
      background: darken($grey, 20%);
    }
  }

  &.btn-white {
    background-color: $white;
    color: $tri-brand-blue-two;

    &:hover {
      background: darken($tri-brand-blue-two, 10%);
      color: $white;
    }
  }

  &.btn-transparent {
    background-color: $light-grey;
    border: 1px solid $grey;
    color: $tri-brand-blue-two;
  }

  &.btn-text {
    background-color: rgba(0, 0, 0, 0);
    color: $tri-brand-blue-two;
    text-decoration: underline;

    &:hover {
      color: $black;
    }
  }

  &.btn-full {
    width: 100%;
  }

  &.btn-checkbox {
    @include appearance-none;
    border: 1px solid $medium-dark-grey;
    height: 18px;
    padding: 0;
    width: 18px;
    position: relative;
    background-color: $white;

    &.checked {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.btn-radio {
    width: 26px;
    height: 26px;
    border-radius: 26px;
    border: 1px solid $medium-grey;
    background-color: #fff;
    padding: 0;
    position: relative;
    &.active {
      &:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $tri-brand-blue-one;
      }
    }
  }
}
