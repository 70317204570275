@import '../configuration/config';

.equote__generate-offers {
  .equote__confirm-quote {
    &.brand-multi {
      .equote__confirm-quote--hero {
        background-color: $royal-blue;
      }
    }
    &.brand-c {
      .equote__confirm-quote--hero {
        background-color: $celebrity-grey;
      }
    }
    &.brand-r {
      .equote__confirm-quote--hero {
        background-color: $royal-blue;
      }
    }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;
    font: $font-base;
    padding: 0;
    display: block;
    margin: 0 auto 0;
    max-width: $app-max-width;
    position: relative;
    width: 100%;

    > h3 {
      margin: 0 0 $col-gutter * 2 0;
      text-align: center;
      width: 100%;
    }

    .equote__confirm-quote-header {
      margin: $col-gutter * 3 0 $col-gutter * 2 + 10 0;
      border-bottom: 1px solid $medium-grey;
      text-align: center;

      h3 {
        margin: 0 0 $col-gutter 0;
      }

      p {
        line-height: 1.8;
        margin: 0;
      }

      p:last-child {
        margin: 0 0 $col-gutter * 3 0;
      }
    }

    .equote__confirm-quote--content-container {
      max-width: 720px;
      border: 1px solid $grey;
      margin: 0 auto 24px;
    }

    .equote__confirm-quote--hero {
      padding: 24px 0 0;
      @media screen and ($bp-min-md) {
        padding: 24px 39px 34px;
      }
      max-width: 720px;
      margin: 0 auto;
      .equote__review-email-header {
        display: flex;
        flex-direction: row;
        padding-bottom: 20px;
        align-items: center;
        justify-content: center;
        .equote__review-email-header-brand {
          max-width: 115px;
          max-height: 55px;
          margin: 0 20px;
          img {
            width: 100%;
            max-height: 100%;
            margin-bottom: 0;
          }
        }
      }
      .equote__review-email-hero {
        min-height: 325px;
        position: relative;

        @media screen and ($bp-min-sm) {
          min-height: 406px;
        }
      }
    }

    .equote__review-email-packages {
      display: block;
      margin: 0 auto $col-gutter + 10;
      max-width: $content-min-width;

      .equote__review-email-social {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-icons {
          display: flex;

          a {
            width: 30px;
            margin: 0 4px;
          }
        }
      }
    }

    .equote__review-email-package {
      margin: 0 0 $col-gutter + 10 0;

      &-terms {
        font-size: 12px;
        padding: 30px 0;
        text-align: center;

        @include MQ(sm) {
          padding: 30px;
        }

        @include MQ(md) {
          padding: 30px 50px;
        }
        @include MQ(lg) {
          padding: 30px 100px 0 100px;
        }
      }
      .equote__review-email-social {
        row-gap: 25px;
        &-icons {
          img {
            max-width: 30px;
            margin: 0 4px;
          }
        }
      }
    }

    .equote__confirm-quote-actions {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto $col-gutter + 10;
      max-width: $content-min-width;

      .btn {
        font-size: $font-size-base - 3;
        text-transform: uppercase;
        width: 48%;

        &:first-of-type {
          margin: 0 2% 0 0;
        }

        &:last-of-type {
          margin: 0 0 0 2%;
        }
      }
    }
  }
}
