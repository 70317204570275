@import '../configuration/config';

.equote__package-details-image-slider {
  &--item {
    width: 100%;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-bottom: 36%;
  }

  &--text {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;

    @include MQ(md) {
      width: 75%;
    }

    @include MQ(md) {
      top: 20%; //top: 146px;
      left: 12%; //left: 242px;
    }

    h1 {
      color: white !important;
      text-transform: uppercase;
      font-size: 40px !important;
      text-align: center;
      margin-bottom: 0;

      @include MQ(md) {
        text-align: left;
        margin-bottom: 25px;
        font-size: 60px !important;
      }

      @include MQ(xl) {
        font-size: 80px !important;
      }
    }
    p {
      letter-spacing: 0.07em;
      color: white !important;
      font-size: 21px !important;
      text-align: center;
      margin: 0 0 15px 0;
      font-weight: 100;

      @include MQ(md) {
        text-align: left;
        margin: 30px 0;
        font-weight: bold;
        font-size: 23px !important;
        letter-spacing: 0.15em;
      }

      @include MQ(xl) {
        font-size: 31px !important;
      }
    }
  }

  &--img {
    width: 100%;
    display: none;
  }

  &--overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #224561;
    opacity: 0.25;
    z-index: 1;
  }

  .slick-slider.slick-initialized {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slick-prev,
  .slick-next {
    position: unset;
    margin: 0 5px;
    transform: none;

    @include OnIE() {
      position: relative;
    }
  }
  .slick-arrow.slick-prev {
    &:before {
      content: '' !important;
    }
  }
  .slick-next {
    order: 4;

    @include OnIE() {
      right: 0 !important;
    }
  }

  .slick-prev {
    order: 2;

    @include OnIE() {
      left: 0 !important;
    }
  }
  .slick-list {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
  }
  .slick-dots {
    position: unset;
    order: 3;
    width: auto;
    transform: none;

    @include OnIE() {
      position: relative;
      width: auto;
      bottom: 0;
    }
  }

  @at-root {
    .celebrity {
      .equote__package-details-image-slider {
        .slick-prev,
        .slick-next {
          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
    }
    .royal {
      .equote__package-details-image-slider {
        .slick-prev,
        .slick-next {
          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
    }
  }
}

@at-root {
  .celebrity {
    .slick-dots {
      li.slick-active {
        button {
          background-color: $celebrity-blue;
        }
      }
    }
  }
}

@at-root {
  .celebrity {
    .slick-dots {
      li.slick-active {
        button {
          background-color: $celebrity-blue;
        }
      }
    }
  }
  .royal {
    .equote__package-details-ship-image-slider--text {
      h1 {
        color: $white;
      }
    }
  }
}
