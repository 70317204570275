@import '../configuration/config';

.equote__package-detail-modal {
  overflow-y: auto;
  background-color: $white;

  .u-modal {
    background: $white;
    border: 1px solid $grey;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    left: $col-gutter;
    outline: none;
    overflow-x: hidden;
    position: absolute;
    right: $col-gutter;
    top: $col-gutter;
    padding: 30px;

    @media screen and ($bp-min-sm) {
      &.u-modal__xs {
        @include align-x-axis(-50%);
        left: 50%;
        max-width: 320px;
        right: auto;
        top: 40px;
        width: 100%;
      }
    }

    @media screen and ($bp-min-lg) {
      @include align-x-axis(-50%);
      left: 50%;
      max-width: 1040px;
      right: auto;
      top: 40px;
      width: 100%;
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 0;
      padding: 30px 30px 0 0;
    }

    &__close {
      @include appearance-none;
      @include align-y-axis(-50%);
      align-items: center;
      color: $tri-brand-blue-one;
      cursor: pointer;
      display: flex;
      font-family: $sans-serif-stack;
      font-size: $font-size-base + 2;
      font-weight: 400;
      text-transform: uppercase;

      @include MQ(md) {
        font-size: $font-size-base + 6;
      }
    }

    &__close-icon {
      width: 21px;
      margin: 0 0 0 2px;
    }

    &__body {
      width: 100%;
      padding: 0;
    }
  }

  &-itinerary {
    display: none;
    margin-bottom: 64px;
    @include MQ(md) {
      display: block;

      &.extended-view {
        .itinerary-day-container {
          display: flex;

          .column {
            display: flex;
            flex-direction: column;
            &:last-child {
              width: 100%;
              .body {
                border-left: none;
              }
            }
          }

          .header {
            p {
              color: #ffffff;
              background-color: $tri-brand-blue-one;
              flex-direction: row;
              text-transform: uppercase;
              text-align: left;
              padding: 15px 20px;
              font-size: 15px;
              font-weight: 200;
            }
          }

          .body {
            display: flex;
            padding: 15px 20px;
            height: 100%;
            border: 1px solid #c4c4c4;
          }
          p {
            color: #585858;
            text-align: left;
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }
    }
  }

  &-itinerary-sliders {
    display: block;
    margin-bottom: 32px;
    @include MQ(md) {
      display: none;
    }

    .slick-slider {
      overflow: hidden;
      padding: 0;

      @include MQ(lg) {
        padding: 0 64px;
      }
    }

    .slick-list {
      overflow: visible;
      width: 100%;

      @include MQ(md) {
        margin-left: 36px;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-prev {
      left: 3px;
      &:before {
        display: none;
      }
    }

    .slick-next {
      right: 4px;
      background: $royal-blue;

      &:focus,
      &:hover {
        color: transparent;
        outline: none;
        background: $royal-blue;
      }
      &:before {
        display: none;
      }
    }

    .slick-slide {
      padding: 0 38px 0 38px;

      > div {
        width: 100%;
      }

      display: flex;
      height: auto;
      transition: all 0.2s ease;
    }
  }

  &-itinerary-slider {
    &__wrapper {
      border: $grey 1px solid;
    }

    &__row-buttons {
      display: flex;
      justify-content: space-between;

      .btn {
        flex: 1;
        text-align: center;
        margin: 20px 0;
        font-size: 0.9em !important;
        white-space: nowrap;
      }
      .btn-info {
        letter-spacing: 0 !important;
        background: none !important;
        text-decoration: underline;
        color: $tri-brand-blue-one !important;
        text-underline-position: under;
      }
    }

    &__row {
      display: flex;

      &--nowrap {
        display: block;

        .equote__package-detail-modal-itinerary-slider__row-body {
          width: 100%;
        }
      }

      &:nth-child(odd) {
        background-color: $light-grey;
      }
    }

    &__row-head {
      width: 50%;
      text-transform: uppercase;
      padding: 10px 20px;
      color: $azamara-blue;
      font-family: $sans-serif-stack !important;
    }

    &__row-body {
      width: 50%;
      padding: 20px 0;
      font-family: $sans-serif-stack-light !important;
      text-align: center;
      font-size: 14px;
    }
  }

  &-loading {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  &-content {
    &.itinerary-modal-content {
      padding: 30px 0;
      @include MQ(md) {
        padding: 0 64px 145px;
      }

      thead {
        position: sticky;
        top: 0;
      }
    }
    h1,
    h2 {
      color: $font-color-heading;
      font-family: $sans-serif-stack;
      font-weight: $font-weight-heading;
      line-height: $line-height-heading;
      margin: 40px 0 $mb-base 0;
      text-align: center;
      font-size: 1.5em;
    }

    .u-table {
      width: 100%;
      font-family: $sans-serif-stack;
      font-weight: 200;
      border: 0;
      border-spacing: 0;

      th {
        background-color: $tri-brand-blue-one !important;
        color: $white;
        flex-direction: row;
        text-transform: uppercase;
        text-align: left;
        padding: 15px 20px;
        font-size: 14px;
        font-weight: 200;

        &:not(:last-child) {
          border-right: 1px solid $grey;
        }
        span {
          color: $white;

          &:hover,
          &:focus {
            color: $white;
          }
        }
      }

      td {
        text-align: left;
        font-size: 14px;
        padding: 15px 20px;
      }
      td.center {
        text-align: center;
      }

      .primary-row {
        background-color: $white;

        &.secondary-background {
          background-color: $light-grey;
        }
      }

      tr {
        &:nth-child(odd) {
          background-color: $grey;
        }
        &:nth-child(even) {
        }
        td:not(:last-child) {
          border: 0;
        }
      }

      .price-info-button {
        padding: 15px 20px 15px 0;
        text-align: left;
      }

      &.hide-details-icons {
        thead {
          tr {
            th {
              &:first-child {
                border: none;
              }
            }
          }
        }
      }
    }

    .u-table__tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 $col-gutter * 2 0;
    }
  }
}
