@import '../configuration/config';

// Override the equote colors
$tri-brand-blue-one: #15264c;
$tri-brand-blue-two: #004275;
$tri-brand-blue-three: #00205b;
$tri-brand-blue-four: #324e5f;
$tri-brand-blue-five: #3f6bab;

.equote-package-related-sailings-container {
  max-width: 100%;
  .u-table {
    border: 1px solid $grey;

    th {
      background-color: $tri-brand-blue-one !important;
      color: $white;
      flex-direction: row;
      text-transform: uppercase;
      text-align: left;

      &:last-of-type {
        span {
          display: none;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid $grey;
      }
      span {
        color: $white;

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
    .primary--table-row {
      &:nth-child(even) {
        background-color: $white;
      }
      &:nth-child(odd) {
        background-color: $light-grey;
      }
    }
    td {
      text-align: left;
    }
    tr {
      td:not(:last-child) {
        border-right: 1px solid $grey;
      }
    }
    .table-cell-text {
      font-size: 14px;
      color: $medium-dark-grey;

      &--price {
        font-size: 17px;
        color: #000;
      }
      &--promo,
      &--refundable {
        font-size: 12px;
      }
    }

    &.no-footer {
      tfoot {
        display: none !important;
      }
    }
  }

  .equote-package-related-sailings-table-container {
    display: none;

    @include MQ(lg) {
      display: block;
    }
  }

  .equote-package-related-sailings-table {
    padding: 0 2px;
    thead {
      span {
        cursor: default;
      }
      svg {
        display: none;
      }
    }

    @include OnIE() {
      tfoot {
        display: block;
      }
    }
  }

  .similar-sailings--no-results-text {
    text-align: center;
  }
}
