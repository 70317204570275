@import '../configuration/config';

.equote__generate-offers {
  .equote-cabin-table-cabin-selection {
    margin-bottom: 8px;
    label {
      display: flex;
      align-items: center;
      color: $medium-grey;
      font-size: 14px;
      margin: 0;

      span {
        margin-left: 8px;
      }
    }
  }
}
