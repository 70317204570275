@import '../configuration/config';

.equote__package-details-cruise-highlights {
  padding: 60px 0 42px;
  max-width: 1440px;
  margin: auto;
  text-align: center;
  border-bottom: 2px solid $bd-grey;
  p {
    line-height: 1.6 !important;
    max-width: 696px;
    margin: 0 20px;
    font-weight: 100;

    @include MQ(md) {
      line-height: 2 !important;
      margin: 0 auto;
      font-weight: bold;
    }

    @include MQ(lg) {
      margin: auto;
    }
  }
}
