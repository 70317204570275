@import '../configuration/config';

@page {
  margin: 0.5cm 0.5cm;
}
@media print {
  * {
    // Internet Explorer 11
    //  1.Open Internet Explorer 11
    //  2.Click on the gear at the top right
    //  3.Hover above Print
    //  4.Then click Page Setup
    //  5.Check the box for Background Colors and Images

    -webkit-print-color-adjust: exact !important ;
    printer-colors: exact !important;
    color-adjust: exact !important;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  body.ReactModal__Body--open,
  html.ReactModal__Html--open {
    overflow: scroll !important;

    .equote__generate-offers {
      display: none;
    }
  }
  .modal-opened {
    height: auto !important;

    #equote__generate-offers {
      display: none !important;
    }
  }
  .equote__generate-offers {
    .equote_home-page-page {
      .equote__search-filter-group,
      .equote__search-filter-group--brand,
      .equote__search-filter-group--destination,
      .equote__search-filters-form-field {
        page-break-inside: avoid;
      }
    }
    .equote__sailings-full-search {
      .u-page-header {
        button {
          display: none;
        }
      }

      .equote__package-filters-submit {
        display: none;
      }
      .equote__package-filters-form {
        &-group {
          display: flex;
          flex-direction: row;
        }
        &-field {
          flex: 1;
          margin: 0 20px;
        }
      }
      .equote__sailings-full-search-filter {
        display: none;
      }
      .equote__sailings-full-search-table-tabs {
        display: none;
      }
      .equote__sailings-cruises-table {
        [style='overflow-y: auto;'] {
          overflow: hidden !important;
        }
        table {
          display: table;
          flex-direction: column;
          page-break-inside: avoid;
        }
        thead {
          position: relative !important;
          top: 0 !important;
        }
        th,
        td {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: auto !important;
          min-height: auto !important;
          width: 100%;

          &:first-child {
            display: none;
          }

          &:nth-child(3) {
            display: none;
          }

          &:last-child {
            display: none;
          }
        }

        td {
          padding: 0 !important;
          margin: 0 !important;
        }
        tr {
          display: flex;
          min-width: auto !important;
          min-height: auto !important;
        }
        tfoot {
          display: none !important;
        }
      }
    }
    .equote__search-disclaimer {
      page-break-inside: avoid;
    }
    .equote__navigation {
      display: none;
    }
    .equote__footer-links {
      display: none;
    }
  }
  .ReactModalPortal {
    .u-fade {
      padding: 0 !important;
      overflow: scroll !important;
      bottom: unset !important;
      position: relative !important;
      top: unset !important;
    }
    .u-modal__header--sticky {
      display: none !important;
    }
    .equote__package-detail-modal {
      max-height: 100% auto;
      overflow: scroll !important;
    }
    .u-modal--preview-package {
      height: auto !important;
      top: 0;
      transform: translateX(-50%);
      box-shadow: none;
    }
    .equote__package-details {
      height: auto !important;

      h1 {
        font-size: 2em !important;
      }
      &-header {
        height: 80px;
        &--brand-logo-container {
          display: block;
        }

        &--equote-logo-container {
          margin: 0;
        }
        &--inner {
          min-height: auto;
        }
      }
    }
    .equote__package-details-header {
      &--agent-logo-container {
        img {
          max-width: 110px;
        }
      }
      &--equote-logo-container {
        padding-left: 15px;
      }
    }
    .equote-agent-details-banner {
      border: none;
      background-color: $light-grey;
      max-height: 100%;

      &--inner {
        padding: 10px;

        img {
          margin-left: auto;
          margin-top: 10px;
        }
      }
      &--contacts {
        width: 100%;
        display: flex;

        .item {
          flex: 1;
        }
      }
      &--contacts--logo {
        display: block;
        max-width: 180px;
      }
      &--button {
        display: none;
      }
      button {
        display: none;
      }
    }
    .equote__package-details-outline {
      width: 100%;
      margin: 20px 0 !important;
      border: $border-medium;
      box-shadow: 5px 5px 5px $grey;
      padding-bottom: 20px;
      page-break-inside: avoid;

      &-print-button {
        display: none;
      }

      &#mid-width-alt {
        widows: 100% !important;
        margin: 0;
      }
      &-share {
        display: none;
      }

      &-fees {
        p {
          text-align: left;
          padding-left: 10px;
        }
      }

      &--title {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        font-weight: 900;

        p {
          padding: 0 0 0 25px;
          margin: 0;
        }
      }
      &--title-logo {
        max-width: 200px;
      }
      &--table {
        display: flex;
        flex-wrap: wrap;
      }
      &--table-cell {
        width: 33%;
        border: none;

        &:last-of-type {
          margin-top: 30px;
          width: 100%;

          p:last-of-type {
            font-size: 24px;
            font-weight: 900;
          }
        }

        &--heading {
          background-color: transparent !important;
          color: $black !important;
          padding: 0 10px !important;
          font-size: 14px;
          min-height: auto;
        }

        &--info {
          padding: 0 10px;
          margin: 0;
          font-size: 18px;
          line-height: 1.4;
        }
      }
    }
    .equote__package-details-image-slider {
      &--item {
        padding: 0;
        overflow: hidden;
      }
      .slick-list {
        margin: 0;
      }
      .slick-track,
      .slick-slide,
      .slick-current {
        width: 100% !important;
        transform: none !important;
      }

      .slick-slide:not(:first-of-type),
      .slick-dots,
      .slick-arrow {
        display: none !important;
      }
      .equote-brand-preview-carousel-img {
        display: block;

        &-container {
          background-image: none;
          padding: 0;
        }
      }
      .equote__package-details-image-slider--item {
        min-height: 290px;
      }
    }
    .equote__package-details-navigation {
      display: none;
    }
    .equote__package-details-cruise-highlights {
      page-break-inside: avoid;
      padding: 10px;

      h1 {
        padding: 0;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
        margin: 0 auto;
        text-align: justify;

        br {
          display: none;
        }
      }
    }
    .equote__package-details-itinerary--header {
      page-break-inside: avoid;
    }

    .equote__package-itinerary-print {
      display: block;

      &-destination-image {
        max-height: 280px;
        overflow: hidden;

        img {
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      h2 {
        text-align: center;
        margin: 12px 0;
      }

      .u-table td {
        font-size: 12px;
      }
    }
    .slick-slider {
      display: none;
    }

    .equote__package-details-ship-details {
      page-break-inside: avoid;

      .equote__package-details-ship-image-slider-img-container {
        padding: 116px;
      }

      &--table--row {
        padding: 0;

        p {
          line-height: 1.4;
        }
      }

      &--ship-description {
        float: left;
        width: 90vw;
        padding: 20px 0 0 0;
        text-align: justify;

        p {
          margin: 0;
          max-width: 1440px;
        }
      }
      &--slider-container {
        width: 50%;

        .equote-brand-preview-carousel-img-container {
          padding: 116px;
        }

        .slick-track,
        .slick-slide,
        .slick-current {
          width: 100% !important;
          transform: none !important;
        }

        .slick-slide:not(.slick-current),
        .slick-dots,
        .slick-arrow {
          display: none !important;
        }
      }
      &--flex-container {
        display: flex;
        flex-direction: row;
      }
      &--ship-specs-toggle {
        display: none;
      }
      &--table {
        margin-left: 10px;
        max-height: 232px;
        display: flex;
        flex-direction: column;
        overflow: unset !important;
      }
      &--table--row {
        flex: 1 1 100%;

        p {
          font-size: 14px;
          margin-left: 10px !important;
        }
      }
    }
    .equote__package-details-state-rooms {
      page-break-inside: avoid;

      & > h1 {
        padding: 0;
      }

      &-tables {
        display: block;
      }
      &-slider {
        display: none;
      }
    }
    .equote-package-state-room--features-description {
      flex: 0 1 60% !important;
      height: auto !important;

      &-print {
        display: block !important;
      }
      &-display {
        display: none;
      }

      p {
        width: 100%;
      }
    }
    .equote__package-details-state-room {
      page-break-inside: avoid;
      margin: 30px 0;

      &--image-container {
        display: none;
      }
      &--body {
        display: table;
        width: 100%;

        &--details {
          display: flex;
          flex-direction: row !important;
          width: 100%;
          height: 100%;
          border: 1px solid $bd-grey;
        }
      }
      &--header {
        &--title {
          p {
            margin: 0 !important;
            padding: 20px 0;
            white-space: wrap !important;
          }
        }
      }

      &--sub-table {
        flex: 1 1 40% !important;
        border-right: 1px solid $bd-grey;
      }
      &--sub-table--row {
        flex: 1 1 !important;
        height: 50%;

        &:last-child {
          border-bottom: none !important;
          flex: 1 1 100%;
        }
      }
      &--sub-table--row--item {
        flex: 1 1 40% !important;

        padding: 5px 15px !important;

        p {
          text-align: left !important;
          width: 100%;
        }

        &:first-child {
          &:nth-of-type(2) {
            white-space: nowrap;
          }
        }

        &:nth-of-type(2) {
          flex: 1 1 60% !important;
        }
      }
      &--body--flex-container {
        margin-bottom: 20px;
      }
      &--body--disclaimer {
        right: 1px;
        bottom: -4px;
      }
    }
    .equote-package-related-sailings-container {
      display: none;
    }
    .equote__package-details-agent-footer {
      &--accolades-wrapper {
        padding: 0;
        page-break-inside: avoid;

        .accolade__columns {
          padding: 10px;
        }

        .accolade__columns--wrapper {
          display: flex;
        }
      }
      .equote-agent-accolade--black {
        padding: 0;
      }
    }
    .equote__package-details-footer {
      page-break-inside: avoid;
      &-container {
        margin: 0 auto !important;
      }
      &-links {
        display: none !important;
      }
      &-rights {
        border-top: 1px solid $bd-grey;
        padding-top: 10px;
      }
    }

    .equote-agent-footer {
      page-break-inside: avoid;

      &-agency-logo {
        display: block !important;
        margin: 0 !important;
        padding: 22px 0 0 0 !important;
        max-width: 100% !important;
        flex: 1;

        img {
          max-width: 160px;
          display: block;
          margin-left: auto;
        }
      }

      &--contacts {
        flex: 1;
        display: block;
        padding-left: 20px;

        .item {
          display: block !important;
          margin: 22px 0;
        }

        p {
          line-height: 1.4;
        }
      }
      &--header {
        padding: 40px;
        margin-bottom: 0;

        p {
          border: none;
        }
      }
      &--inner {
        display: flex;
        flex-direction: row;
        padding: 0 0 40px 0;

        justify-content: center;
      }

      &--header-buttons-wrapper {
        position: relative;
      }
      &--header-buttons {
        display: none;
      }
      .equote-agent-details-banner {
        display: none;
      }

      .u-content-container {
        margin: 20px auto 0 auto;
        background-color: $light-grey;
      }
    }
    .equote-agent-accolade-text {
      padding: 0;
    }
    .equote__package-details-footer {
      page-break-inside: avoid;
      &-rights {
        page-break-inside: avoid;
        display: block;
        margin: 10px 0;
        padding-top: 10px;
        text-align: center;
      }
    }
  }
}
