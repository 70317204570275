@import '../configuration/config';

.equote-land-program-itinerary-preview {
  p {
    margin: 0;
  }
  .equote-land-program-itinerary-preview-title {
    text-transform: capitalize;
  }
  .equote-land-program-itinerary-preview-header {
    padding-left: 16px;
    background-color: $tri-brand-blue-one;
    color: #fff;
  }
  .equote-land-program-itinerary-preview-day {
    display: flex;
    width: 100%;
    .equote-land-program-itinerary-preview-day-column {
      &:first-child {
        flex-shrink: 0;
        min-width: 200px;
      }
      &:not(:first-child) {
        width: 100%;
      }
      .equote-land-program-itinerary-preview-day-column-header {
        background-color: $tri-brand-blue-one;
        color: #fff;
        padding: 0 16px;
        height: 37px;
      }
      .equote-land-program-itinerary-preview-day-column-body {
        padding: 8px 16px;
        p {
          font-size: 14px;
          color: $medium-dark-grey;
          line-height: 1.6;
        }
      }
    }
  }
}
