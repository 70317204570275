@import '../configuration/config';

.equote__error {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $col-gutter * 2 + 10 0;
  position: relative;
  text-align: center;

  @media screen and ($bp-min-xl) {
    padding: $col-gutter * 2 + 10 $col-gutter * 10 $col-gutter * 2 + 10;
  }

  .btn {
    margin-bottom: $col-gutter;
    text-transform: uppercase;
    background-color: white;
    white-space: nowrap;

    @media screen and ($bp-min-xl) {
      position: absolute;
      margin-bottom: 0;
      left: $grid-unit * 2;
      top: auto;
    }

    @media screen and ($bp-min-xxl) {
      left: 0;
    }

    .equote__error-back-content {
      display: flex;
      align-items: center;

      .equote__error-back-icon {
        border-right: 1px solid $grey;
        padding-right: 8px;
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
    }
  }

  h2 {
    margin: 0 0 $col-gutter - 10 0;
  }

  h2,
  p {
    width: 100%;
  }
}