@import '../configuration/config';
.equote-package-related-sailings-container {
  .u-table {
    width: 100%;
    font-family: $sans-serif-stack-light;
    border: 0;
    border-spacing: 0;
    border: 1px solid $grey;

    th {
      background-color: $celebrity-blue;
      color: $white;
      flex-direction: row;
      text-transform: uppercase;
      text-align: left;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;

      &:not(:last-child) {
        border-right: 1px solid $grey;
      }
      span {
        color: $white;

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }

    td {
      text-align: left;
      font-size: 11px;
      padding: 15px 20px;

      @include MQ(md) {
        font-size: 16px;
      }
    }
    td.center {
      text-align: center;
    }

    .primary-row {
      background-color: $white;

      &.secondary-background {
        background-color: $light-grey;
      }
    }

    tr {
      &:nth-child(odd) {
        background-color: $grey;
      }
      &:nth-child(even) {
      }
    }
    .u-table-sold-out-state-room-cell {
      text-align: center;
      p {
        text-transform: uppercase;
        color: $sold-out-text;
      }
    }
    .price-info-button {
      padding: 15px 20px 15px 0;
      text-align: left;
    }

    &.hide-details-icons {
      thead {
        tr {
          th {
            &:first-child {
              border: none;
            }
          }
        }
      }
    }
  }

  .u-table__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 $col-gutter * 2 0;
  }
}
