@import '../configuration/config';

.u-table {
  border: 1px solid $grey;
  cursor: default;

  th {
    background-color: $tri-brand-blue-one !important;
    color: $white;
    flex-direction: row;
    text-transform: uppercase;
    text-align: left;

    &:not(:last-child) {
      border-right: 1px solid $grey;
    }
    span {
      color: $white;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  td {
    text-align: left;
  }

  .primary-row {
    td {
      background-color: $white;
    }

    &.secondary-background {
      td {
        background-color: $light-grey;
      }
    }
  }

  tr {
    td:not(:last-child) {
      border-right: 1px solid $grey;
      //padding-bottom: 0;
    }
  }

  .active-price {
    background-color: $white;
    position: relative;
    border-top: 1px solid $medium-grey;
    border-right: 1px solid $medium-grey;
    border-left: 1px solid $medium-grey;
    z-index: 1;

    span {
      color: $black !important;
    }

    &:after {
      display: block;
      content: '';
      width: calc(100% + 2px);
      height: 14px;
      position: absolute;
      background-color: #fff;
      left: -1px;
      bottom: -13px;
      border-left: 1px solid #888888;
      border-right: 1px solid #888888;
    }
    &.cruise-tour-cell {
      &:after {
        width: 100%;
        height: 6px;
        position: absolute;
        background-color: #fff;
        left: 0;
        bottom: -5px;
        border: none;
      }
    }
  }

  .price-info-button {
    padding: 15px 20px 15px 0;
    text-align: left;

    .equote__sailings-table-best-price {
      color: $tri-brand-blue-two;
    }
    .equote__sailings-table-best-price-value {
      text-decoration: underline;
    }

    &:hover {
      .equote__sailings-table-best-price {
        color: $black;
      }
    }
  }

  &.hide-details-icons {
    thead {
      tr {
        th {
          &:first-child {
            border: none;
          }
        }
      }
    }
  }
  &.hide-filter-row {
    tbody:first-of-type {
      & > tr:first-of-type {
        &:not(.equote__sailings-full-search--table-body-row) {
          display: none;
        }
      }
    }
  }

  &.sub-table {
    thead {
      z-index: 0;
      tr {
        &:nth-child(even) {
          background-color: $light-grey;
        }
        th {
          border-right: 1px solid $grey;
          &:first-child {
            // we need this because we are nesting tables.
            border-right: 1px solid $grey;
          }
        }
      }
    }
  }
  &-price-cell {
    min-height: 87px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    @include OnIE() {
      display: block;
      text-align: center;
      position: relative;
      padding: 0;

      & > span {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
      }
      & > button {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        margin-left: 25px;

        @include OnIE() {
          margin-left: 10px;
        }
      }
    }

    &.loading {
      text-align: center;
    }
    &--loading-spinner {
      width: 30px;
      height: auto;
      margin: auto;
    }
  }
}

.u-table__tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 $col-gutter * 2 0;
}
