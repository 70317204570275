@import '../configuration/config';

.equote__generate-offers,
.u-modal {
  //Form & Input
  input,
  textarea {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid $tri-brand-blue-one;
    display: block;
    font-family: $sans-serif-stack;
    font-size: 14px;
    padding: 10px;

    &.error {
      border: 1px solid $error-red;
    }
  }

  label {
    color: #2e5a6d;
    display: block;
    font-size: $font-size-base - 1;
    margin: 0 0 $col-gutter - 12 0;
  }

  input[type='text'],
  input[type='number'] {
    @include appearance-none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  input[type='number'] {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    @include appearance-none;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: $border-light;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    color: $tri-brand-blue-one;
    cursor: pointer;
    font-family: $sans-serif-stack;
    font-size: $font-size-base - 3;
    padding: $col-gutter - 17 0;
    min-width: 70px;
    -moz-appearance: textfield;
    &:disabled {
      background-color: $light-grey;
    }
  }

  select,
  .u-custom-select__field {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    @include appearance-none;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: $border-light;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    color: $tri-brand-blue-one;
    cursor: pointer;
    flex: 1;
    font-family: $sans-serif-stack;
    font-size: $font-size-base - 3;
    margin: 0;
    padding: 12px 32px 12px 0;

    @include MQ(md) {
      margin: 0 $col-gutter 0 0;
    }

    &:disabled {
      background-color: $light-grey;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .u-custom-select {
    display: flex;
    margin: 0 0 $col-gutter - 5 0;
    position: relative;

    select,
    .u-custom-select__field {
      //display: block;
      width: inherit;
      z-index: 1;
    }

    &.u-custom-select--inc-label {
      select,
      .u-custom-select__field {
        padding: 4px 32px 12px 0;
      }
    }

    @media screen and ($bp-max-md) {
      width: 100%;
    }

    @media screen and ($bp-min-xl) {
      margin: 0;
    }
  }

  .u-custom-select__label {
    color: $medium-grey;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
    width: 100%;

    &-close {
      width: 14px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .u-custom-select__arrow {
    @include align-y-axis(-50%);
    background-attachment: scroll;
    background-color: rgba(0, 0, 0, 0);
    background-image: url(../../../assets/icons/arrow-down.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 21px auto;
    right: 29px;
    position: absolute;
    top: 50%;
    width: 15px;
    height: 14px;
    z-index: 0;
  }

  .u-custom-select__accordion {
    background: $white;
    border-bottom: 1px solid $tri-brand-blue-one;
    border-left: 1px solid $tri-brand-blue-one;
    border-right: 1px solid $tri-brand-blue-one;
    bottom: 0;
    display: none;
    left: 0;
    padding: $col-gutter - 10;
    position: absolute;
    right: $col-gutter;
    transform: translateY(100%);
    z-index: 5;

    &.active {
      display: block;
    }

    button {
      @include appearance-none();
      background-color: $white;
      border: 1px solid $tri-brand-blue-one;
      color: $tri-brand-blue-one;
      cursor: pointer;
      margin: 0 0 $col-gutter - 10 0;
      padding: $col-gutter - 10;
      transition: background-color 0.25s linear;
      width: 100%;

      &:last-of-type {
        margin: 0;
      }

      &:hover {
        background-color: $tri-brand-blue-one;
        color: $white;
      }
    }
  }

  input[type='submit'],
  button {
    @include appearance-none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .u-custom-email__chips {
    border-radius: 0;
    height: auto;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    max-width: 614px;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px;

    &.empty {
      > span[data-placeholder] {
        @include align-y-axis(-50%);
        color: $dark-grey;
        font-family: $sans-serif-stack;
        font-size: $font-size-base - 2;
        top: 50%;
      }
    }

    [data-tag] {
      margin: 2px;
    }

    input {
      flex: 1 1 auto;
      height: auto;
      margin: 0;
      padding: 0;
    }
  }

  .equote_home-page-page {
    @include OnIE() {
      select {
        min-width: 280px;
      }
    }
  }
}
