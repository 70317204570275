@import '../configuration/config';

.equote__cabins-land-packages-container {
  .equote__cabins-land-packages-filter-buttons {
    margin: 32px 0 78px;

    .equote__cabins-land-packages-filter-buttons-title {
      font-size: 13px;
      font-weight: 700;
      color: $tri-brand-blue-three;
      margin-bottom: 16px;
    }
    .equote-primary-select-button-container {
      max-width: 200px;
    }
  }
  .equote__cabins-land-packages-pre-selected-package {
    .equote__cabins-land-packages-pre-selected-package--title {
      text-transform: uppercase;
      font-size: 18px;
      color: $medium-dark-grey;
      margin: 78px 0 29px;
    }

    .equote__cabins-land-packages-pre-selected-package--card {
      border: 1px solid $tri-brand-blue-one;
      margin-bottom: 64px;
      .equote__cabins-land-packages-pre-selected-package--card-header {
        background-color: $tri-brand-blue-one;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 36px;

        .equote__cabins-land-packages-pre-selected-package--card-header-check {
          width: 26px;
          height: 26px;
          border-radius: 26px;
          background-color: #fff;
          border: 1px solid $medium-grey;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 18px;
          }
        }

        p {
          margin: 0 64px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      .equote__cabins-land-packages-pre-selected-package--card-body {
        padding: 32px 64px 32px 128px;
        p {
          font-size: 14px;
          color: $medium-dark-grey;
          font-weight: $font-weight-medium;
        }
      }
    }
  }

  .equote__cabins-land-packages-available-packages {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;

    @include MQ(xl) {
      flex-direction: row;
    }

    .equote__cabins-land-packages-available-packages-column {
      width: 100%;

      @include MQ(xl) {
        &.half {
          width: 50%;

          &:first-child {
            padding-right: 32px;
          }
          &:not(:first-child) {
            padding-left: 32px;
          }
        }
      }

      .equote__cabins-land-packages-available-packages-column-title {
        text-transform: uppercase;
        font-size: 18px;
        color: $medium-dark-grey;
      }

      .equote__cabins-land-packages-available-package {
        display: flex;
        align-items: center;
        border: 1px solid $grey;

        &:nth-child(even) {
          background-color: $light-grey;
        }

        &.disabled {
          opacity: 0.5;
        }

        .equote__cabins-land-packages-available-package-select-button-container {
          width: 70px;
          height: 100%;
          text-align: center;

          flex-shrink: 0;
        }

        p {
          margin: 0;
        }
        .equote__cabins-land-packages-available-package-details-container {
          width: 100%;
          display: flex;
          align-items: center;
          border-left: 1px solid $grey;
          min-height: 80px;
          flex-wrap: wrap;
          &.has-warning.half {
            padding: 28px 0 8px;
          }
          .equote__cabins-land-packages-available-package-details {
            display: flex;
            flex: 1;
            justify-content: stretch;
            padding-left: 16px;
            .equote__cabins-land-packages-available-package-details-column {
              flex: 1;
              padding: 0 8px;

              p {
                font-size: 17px;
                line-height: 1;
                color: #000;

                &:not(:first-child) {
                  font-size: 15px;
                  margin-top: 8px;
                }
              }
            }
          }
          .equote__cabins-land-packages-available-package-details-warning {
            padding-left: 16px;
            margin-top: 8px;
            flex: 1;
            p {
              line-height: 1;
            }

            &.half {
              order: 3;
              flex-basis: 100%;
            }
          }
        }
        .equote__cabins-land-packages-available-package-details-button-container {
          padding-right: 32px;
          flex: 1;
          max-width: 128px;
          button.btn {
            border: 1px solid $grey;
          }
        }
      }
    }
  }
}
