@import '../configuration/config';

.equote__sailings-full-search {
  .equote__sailings-full-search-filter {
    margin: 30px 0;
    width: 100%;

    &-title {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
    }

    &-buttons {
      display: flex;
      flex-direction: column;

      @include MQ(sm) {
        flex-direction: row;
      }

      .equote-primary-select-button-container {
        margin: 0 4px;
      }
    }
  }
}
