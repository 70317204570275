@import '../configuration/config';

.equote-selected-package-list-modal {
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $light-grey;
    padding: 0 16px;
    h4 {
      font-size: 20px;
    }
    &-close-btn {
      &.btn {
        background-color: $light-grey;
        font-size: 20px;
      }
      &--inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        img {
          height: 32px;
        }
      }
    }
  }

  &--package-list {
    padding: 0 16px 16px;
    &--item {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 20px;
        }
        &--remove-btn {
          text-decoration: underline;

          &--inner {
            display: flex;
            align-items: center;

            img {
              width: 12px;
              margin-right: 8px;
            }
          }
        }
      }
      &-row {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          line-height: 1.5;
          font-size: 14px;
          &:first-child {
            width: 200px;
          }
        }
      }
    }
  }
}
