@import '../configuration/config';

.equote__search-disclaimer {
  order: 4;
  width: 100%;
  margin: 55px 0;

  h5 {
    color: $medium-dark-grey;
    margin: 0 0 $col-gutter - 10 0;
  }

  p {
    line-height: 1.6;
  }
}
