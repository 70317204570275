@import '../configuration/config';

.equote__package-details-ship-details {
  padding: 20px 32px;

  @include MQ(md) {
    padding: 64px 32px;
  }

  .section-title {
    text-align: center;
    padding: 0 !important;

    @include MQ(lg) {
      text-align: left;
    }

    small {
      font-size: 16px;
      line-height: 0;
      display: block;
      margin: 15px 0 45px 0;

      @include MQ(lg) {
        font-size: 32px;
        line-height: 45px;
        display: inline;
        margin: 0;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    @include MQ(lg) {
      flex-direction: row;
    }

    .left {
      width: 100%;
      @include MQ(lg) {
        width: 66%;
      }
    }

    .right {
      width: 100%;

      @include MQ(lg) {
        width: 33%;
        padding-left: 16px;
      }
    }

    .ship-details-table {
      &.desktop {
        display: none;
      }
      &.mobile {
        transition: max-height 0.6s ease-out;
        max-height: 0;
        overflow: hidden;
      }
      &.mobile.open {
        display: flex;
        max-height: 500px;
      }
      flex-direction: column;

      @include MQ(lg) {
        &.desktop {
          display: flex;
        }
        &.mobile,
        &.mobile.open {
          display: none;
        }
      }

      .row {
        flex: 1;
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0 10px;
        &:nth-child(even) {
          background-color: #e5e5e5;
        }

        p {
          margin: 0;
          @include MQ(lg) {
            margin: 18px 0;
          }
        }

        .label {
          grid-column-start: 1;
        }
        .value {
          grid-column-start: 2;
        }
      }
    }
    .virtual-tour-container {
      width: 100%;
      padding-top: 32px;

      .u-virtual-tour-trigger {
        width: 100%;
      }
    }

    .mobile-tech-toggle {
      padding: 0;
      display: flex;
      gap: 4px;
      text-transform: uppercase;
      font-size: 1em;

      @include MQ(lg) {
        display: none;
      }

      svg {
        transform-origin: center center;
        transform: rotate(90deg) scale(0.7, 0.7);
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        margin-top: -4px;
      }

      &.active {
        svg {
          margin-top: 0;
          transform: rotate(-90deg) scale(0.7, 0.7);
        }
      }
    }
  }

  @at-root {
    .celebrity {
      .section-title {
        small {
          text-transform: capitalize;
        }
      }
      .equote__package-details-ship-details .ship-description p {
        font-weight: $celebrity-font-weight-regular;
        line-height: 1.6;
      }
      .equote__package-details-ship-details .row p {
        font-weight: 400;
      }
    }
    .royal {
      .section-title {
        small {
          text-transform: capitalize;
        }
      }
    }
  }
}
