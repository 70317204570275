@import '../configuration/config';

.u-nested-cabins-table-container {
  &.u-table {
    table {
      thead {
        padding: 0;
        tr {
          height: auto;
          background-color: #2a467b !important;
          th {
            border-right: none;
            padding: 12px;
            font-size: 15px;
            // this along with the tr background-color above is a hack so we can see
            // the full BEST NON-REFUNDABLE RATE heading.
            background-color: transparent !important;
            z-index: 0;
            &:after {
              display: none;
            }
            &:first-child {
              border-bottom: 1px solid #e0e0e0;
            }
          }
        }
      }
      .equote-cabins-table-guest-count-heading {
        font-size: 15px;
        color: #000;
        font-weight: 400;
      }
    }
  }
}
