@import '../configuration/config';
.equote__package-itinerary-print {
  display: none;

  .u-table {
    width: 100%;

    tr:nth-child(odd) {
      background-color: $grey;
    }

    th {
      background-color: $royal-blue !important;
      color: $white;
      flex-direction: row;
      text-transform: uppercase;
      text-align: left;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;

      span {
        color: $white;

        &:hover,
        &:focus {
          color: $white;
        }
      }
    }
    .primary--table-row {
      &:nth-child(even) {
        background-color: $white;
      }
      &:nth-child(odd) {
        background-color: $light-grey;
      }
    }
    td {
      text-align: left;
      padding: 15px 20px;
      font-size: 12px;
    }
    tr {
    }
    .table-cell-text {
      font-size: 14px;
      color: $medium-dark-grey;

      &--price {
        font-size: 17px;
        color: #000;
      }
      &--promo,
      &--refundable {
        font-size: 12px;
      }
    }

    &.no-footer {
      tfoot {
        display: none !important;
      }
    }
  }
}
