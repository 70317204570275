@import '../configuration/config';

.equote__cabins {
  .equote__cabins-content {
    padding: 0 10px;

    @include MQ(xxl) {
      padding: 0;
    }
  }

  .equote__cabins-table-container {
    &:not(.active) {
      display: none;
    }
  }

  .equote__cabins-table-package {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: $font-size-base + 1;
      font-weight: 600;
      //margin-bottom: $col-gutter / 2;
    }

    .info {
      font-size: $font-size-base - 1;
      line-height: $line-height-copy;
    }
    &__text,
    &__details {
      background-color: $tri-brand-blue-one;
      color: $white;
      width: 100%;
    }
    &__details {
      margin: 5px 0;

      &--post-cruise,
      &--pre-cruise {
        display: flex;
        p {
          font-size: $font-size-base + 1;
          font-weight: 600;
          margin: auto 0;
          text-transform: uppercase;
        }
        &-flag,
        &-flag {
          background-color: $tri-brand-blue-hover;
          padding: 3px 45px 3px 20px;
          position: relative;
          overflow: hidden;

          p {
            margin: 0;
            color: white;
            font-size: 16px;
            font-weight: 400;
            padding: 10px;
            text-transform: capitalize;
          }

          &:before,
          &:after {
            content: '';
            position: absolute;
            right: -13px;
            height: 100px;
            width: 40px;
            background: $tri-brand-blue-one;
          }

          &:before {
            top: -24px;
            transform: rotate(-20deg);
          }

          &:after {
            transform: rotate(20deg);
            bottom: -24px;
          }
        }
      }
    }

    &__info {
      display: flex;
      width: 100%;
      background-color: $tri-brand-blue-one;
      padding: 30px;
      flex-direction: column;

      @include MQ(lg) {
        flex-direction: row;
        padding: 20px 30px 20px 30px;
        align-items: center;
      }

      &-sail-wrapper {
        display: flex;
        flex-direction: column;

        @include MQ(md) {
          flex-direction: row;
        }
      }

      &-sail {
        padding: 10px 0;

        @include MQ(md) {
          padding-right: 40px;
        }
      }
      &-sail-title {
        margin: 0;
        line-height: 1.4;
        font-weight: 500;
        font-size: 0.6em;
        color: $white;
        text-transform: uppercase;
      }
      &-sail-data {
        margin: 0;
        line-height: 1.3;
        color: $white;
        font-size: 1em;
      }

      &-description-title {
        margin: 0;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 20px;
        font-size: 1.2em;
        color: $white;
        text-transform: uppercase;
      }
      &-details-btn {
        display: block;

        @include MQ(lg) {
          margin-left: auto;
        }

        .btn {
          background-color: $white;
        }
      }
    }
  }

  .equote__cabins-table-content {
    &--header {
      padding: 30px 20px;

      @include MQ(lg) {
        padding: 50px 100px 50px 100px;
      }
    }
    .equote__cabins-table-content--instructions {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 16px;
      p {
        max-width: 320px;
        text-align: center;
        padding: 0 32px;
        font-size: 14px;
        color: $medium-grey;
        line-height: 1.1;
        font-weight: 700;
      }
    }
    .equote__cabins-table-accordions {
      border: 1px solid $grey;
      margin-bottom: $col-gutter * 3 + 4;

      .equote__cabins-table-accordion {
        &:nth-child(odd) {
          background-color: $light-grey;
        }

        .equote__cabins-table-heading {
          align-content: center;
          display: flex;
          flex-wrap: wrap;

          @include MQ(xs) {
            flex-wrap: nowrap;
          }

          .equote__cabins-table-toggle {
            align-items: center;
            border-right: 1px solid $grey;
            display: flex;
            justify-content: center;
            min-width: 50px;

            @include MQ(sm) {
              min-width: 80px;
            }

            @include MQ(md) {
              width: 112px;
            }
          }

          .equote__cabins-table-details {
            flex: 1;
            padding: $col-gutter;

            .title {
              font-weight: 600;
            }

            .price {
              font-size: $font-size-base - 4;
              color: $medium-grey;
            }
            .taxes {
              font-size: 12px;
            }
          }
          .equote__cabins-table-edit {
            align-items: center;
            display: flex;
            justify-content: center;
            max-width: 320px;
            padding: 0;
            width: 100%;

            @include MQ(xs) {
              padding: 0 $col-gutter + 10;
            }

            .btn {
              border: 1px solid $grey;
              line-height: 1;
              padding: $col-gutter - 10 $col-gutter;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .equote__cabins-table {
    background-color: $white;
    border-bottom: 1px solid $grey;
    border-top: 1px solid $grey;
    display: none;
    padding: $grid-unit;

    @media screen and ($bp-min-md) {
      padding: $grid-unit * 5;
    }

    &.active {
      display: block;
      tbody {
        tr {
          &:nth-child(even) {
            background-color: $lighter-grey;
          }

          th {
            font-size: 0.6em;

            @include MQ(sm) {
              font-size: 0.75em;
            }

            span {
              display: block;
              width: 100%;
              text-align: center;
              @include MQ(sm) {
                display: inline;
                width: auto;
                text-align: left;
              }
            }
            svg {
              display: none;
              @include MQ(sm) {
                display: inline;
              }
            }
          }
          td {
            vertical-align: top;
          }

          th,
          td {
            font-size: 0.6em;
            padding: 30px 8px !important;

            @include MQ(sm) {
              padding: 20px !important;
              font-size: 0.75em;
            }

            &:first-of-type,
            &:nth-of-type(2) {
              text-align: center;
              @include MQ(sm) {
                text-align: left;
              }
            }
          }
        }
      }
      .equote__sailings-table-best-price {
        font-weight: 600;
        font-size: $font-size-base - 6;
        @include MQ(sm) {
          font-size: $font-size-base - 3;
        }
      }
      .equote__sailings-table-best-price-description {
        color: $medium-grey;
        word-break: break-word;
        word-wrap: break-word;
      }
      .equote__sailings-table-taxes {
        display: block;
      }
      .equote__cabins-table--header {
        button {
          margin-left: auto;
        }
        .btn-text {
          color: $tri-brand-blue-one;
          text-transform: uppercase;
          text-decoration: none;

          .u-modal__close-icon {
            margin-top: 2px;
          }
        }
      }
    }

    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h6 {
        color: $black;
        font-weight: 400;
      }
      .btn {
        span {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
