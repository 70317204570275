@import '../configuration/config';

.equote__footer {
  background-color: $white;
  border-top: $border-main;
  font-size: $font-size-base - 1;
  padding-bottom: 80px;
  .equote__footer-container {
    display: flex;
    flex-wrap: wrap;
    padding: $col-gutter 40px;

    @media screen and ($bp-min-xl) {
      align-items: center;
      flex-direction: row;
    }
  }

  .equote__footer-rights {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0 $col-gutter;
  }

  .equote__footer-links {
    .btn {
      padding: 15px 30px;
    }
    margin-left: auto;
    @media screen and ($bp-min-xl) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
    .btn-text {
      text-align: center;
    }
  }

  .equote__footer-link.btn {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;

    @media screen and ($bp-min-xl) {
      &:after {
        content: '|';
        position: absolute;
        right: 0;
        top: 50%;
        @include align-y-axis(-50%);
      }
    }

    &:last-of-type {
      &:after {
        content: none;
      }
    }
  }
}
