@import '../configuration/config';

.equote__land-programs-detail-modal {
  background-color: #fff;

  .section-header {
    background-color: $tri-brand-blue-one;
    color: #fff;
    min-height: 43px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    p {
      font-size: 15px;
      margin: 0;
      text-transform: uppercase;
    }
  }
  .equote__land-programs-detail-modal-content {
    padding: 80px;
    position: relative;

    .equote__land-programs-detail-modal-close-button {
      position: absolute;
      right: 32px;
      top: 32px;
    }
    .equote__land-programs-detail-modal-content-header {
      background-color: $tri-brand-blue-one;
      padding: 16px 32px;

      h4 {
        font-size: 25px;
        color: #fff;
        margin: 0;
      }
    }

    .equote__land-programs-detail-modal-table {
      display: flex;
      border: 1px solid $medium-grey;
      p {
        margin: 0;
      }

      .equote__land-programs-detail-modal-table--column {
        &:first-child {
          border-right: 1px solid $medium-grey;
          width: 170px;
        }
        &:not(:first-child) {
          width: 100%;
        }
      }

      .equote__land-programs-detail-modal-table--column-details {
        padding: 16px;
        p {
          color: #585858;
          line-height: 1.6;
          font-size: 14px;
        }
        .equote__land-programs-detail-modal-table--column-details-sub-table {
          &:first-child {
            margin-bottom: 16px;
          }
          p {
            font-size: 15px;
            color: $tri-brand-blue-one;
            line-height: 1.5;
            &:first-child {
              font-weight: 700;
            }
          }
        }
      }
    }

    .equote__land-programs-detail-modal-highlights {
      border: 1px solid $medium-grey;
      border-top: none;

      .equote__land-programs-detail-modal-highlights-list {
        display: flex;
        flex-wrap: wrap;
        padding: 32px;
        .equote__land-programs-detail-modal-highlights-list-item {
          width: 50%;
          flex: 1 0 auto;
          padding: 8px 32px 8px 0;
          display: flex;
          align-items: flex-start;
          img {
            margin-right: 16px;
            margin-top: 4px;
            width: 16px;
          }
          p {
            font-size: 15px;
            color: $medium-dark-grey;
            line-height: 1.6;
          }
        }
      }
    }
  }
}
