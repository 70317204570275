@import '../configuration/config';

.equote__package-details-outline--land-program {
  order: 4;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $bd-grey;
  padding: 16px 0;
  .equote__package-details-outline--land-program-flag {
    display: flex;
    align-items: center;
    background-color: #00aac6;
    padding: 8px 32px 8px 8px;
    margin-right: 25px;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      right: -20px;
      width: 45px;
      height: 19px;
      background-color: #fff;
    }

    &:before {
      transform: rotate(57deg);
      top: 0;
    }
    &:after {
      transform: rotate(-57deg);
      bottom: 0;
    }

    p {
      margin-left: 15px;
      color: #fff;
      text-transform: capitalize;
    }
  }
  .equote__package-details-outline--land-program-title {
    p {
      font-size: 22px;
      color: $tri-brand-blue-one;
    }
  }

  @at-root {
    .royal {
      .equote__package-details-outline--land-program {
        .equote__package-details-outline--land-program-flag {
        }
      }
    }
    .celebrity {
    }
  }
}
