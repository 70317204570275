@import '../configuration/config';

.equote__search-filters-form-promo-modal {
  h2 {
    max-width: 81%;
  }
}

.equote__search-filters-form-promos {
  .u-modal__header {
    margin: 0;
    padding: 30px 30px 20px 30px;
  }
  .u-modal__body {
    padding: 0 20px 20px 20px;
  }

  .equote__search-filters-form-promo {
    .equote__search-filters-form-promo-modal-link {
      color: $tri-brand-blue-two;
      text-decoration: underline;
      font-size: 14px;
      margin-left: 8px;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        color: $anchor-hover;
      }
    }

    &-checkboxes {
      display: flex;
      flex-wrap: wrap;
      padding-top: $col-gutter;
      flex: 1;
    }

    &-checkboxes-group {
      margin: 0px;
    }

    &-loyalty {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      label {
        font-family: $sans-serif-stack;
        font-weight: 400;
      }
      input {
        margin: 0;
        order: 1;
        width: 100%;

        @include MQ(sm) {
          width: auto;
        }
      }
      img {
        padding-left: 30px;
        order: 2;
      }
      button {
        order: 4;
        margin: 0 auto;

        @include MQ(sm) {
          order: 3;
          margin: 0;
        }
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0;
        width: 100%;
      }
      p {
        width: 100%;
        text-align: center;
        order: 3;

        @include MQ(sm) {
          text-align: left;
          order: 4;
        }
      }
    }

    &-checkbox {
      display: flex;
      width: 100%;
      &.disabled {
        opacity: 0.75;
      }
      button {
        flex-shrink: 0;
      }

      label {
        color: $medium-dark-grey;
        font-size: $font-size-base - 3;
        padding: 0 0 0 $col-gutter - 10;
        color: #585858;
        font-size: 15px;
        padding: 0 0 0 10px;
        font-family: $sans-serif-stack;
        font-weight: 400;
        margin: 0px 0px 15px;
      }

      @media screen and ($bp-min-xl) {
        flex-wrap: wrap;
      }
    }
  }
}
