@import '../configuration/config';

.equote__review-construct-email {
  border: 1px solid $grey;

  //   ========================================================================
  //   BRAND THEME
  //   ========================================================================
  &.brand-multi {
    .equote__review-construct-email {
      &--content-container {
        background-color: $royal-blue;
      }
    }

    .equote__review-email-header-brand {
      margin: 0 $col-gutter - 10;

      @include MQ(sm) {
        margin: 0 $col-gutter;
      }
    }

    .equote__review-email-hero-overlay {
      .overlay-title {
        font-size: $font-size-base + 20;
        letter-spacing: 2.5px;
        line-height: 45px;
        margin: 0 0 $col-gutter 0;

        @include MQ(md) {
          font-size: $font-size-base + 40;
        }
      }
      .overlay-subtitle {
        font-size: $font-size-base;
        letter-spacing: 2.5px;
        line-height: $font-size-base + 5;

        @include MQ(md) {
          font-size: $font-size-base + 10;
        }
      }
    }

    .equote__review-email-basic-content {
      .basic-content__title {
        font-size: 48px;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        font-family: $font-kapra;
      }
      .basic-content__body {
        font-family: 'ProximaNova';
      }
    }

    .equote__review-email-agent-banner {
      font-family: $font-proxima-nova;
      &-title {
        font-family: $font-proxima-nova;
        color: $royal-blue;
      }
      &-subtitle {
        font-family: $font-proxima-nova;
      }
    }
    .equote__review-email-agent-data-col {
      .title {
        font-family: $font-proxima-nova;
      }
      .details {
        font-family: $font-proxima-nova;
      }
    }
  }

  &.brand-c {
    .equote__review-construct-email {
      &--content-container {
        background-color: $celebrity-grey;
      }
    }
    .equote__review-email-hero-overlay {
      .overlay-title {
        font-family: $font-roboto-light;
        font-size: $font-size-base + 42;
        line-height: 45px;
      }
      .overlay-subtitle {
        font-family: $font-roboto-light;
        font-size: $font-size-base + 10;
        line-height: 24px;
        margin: 0 0 $col-gutter - 10 0;
      }
    }
    .equote__review-email-basic-content {
      .basic-content__title {
        font-size: 36px;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
        font-family: $font-roboto-light;
      }
      .basic-content__body {
        font-family: $font-roboto;
      }
    }
    .equote__review-email-agent-banner {
      font-family: $font-roboto;
      &-title {
        font-family: $font-roboto;
        color: $celebrity-grey;
      }
      &-subtitle {
        font-family: $font-roboto;
      }
    }
    .equote__review-email-agent-data-col {
      .title {
        font-family: $font-roboto;
      }
      .details {
        font-family: $font-roboto;
      }
    }
  }

  &.brand-r {
    .equote__review-construct-email {
      &--content-container {
        background-color: $royal-blue;
      }
    }
    .equote__review-email-hero-overlay {
      .overlay-title {
        font-family: $font-kapra;
        font-size: $font-size-base + 62;
        line-height: 34px;
      }
      .overlay-subtitle {
        font-family: $font-proxima-nova;
        font-size: $font-size-base + 5;
        letter-spacing: 2.5px;
        line-height: $font-size-base + 5;
      }
    }
    .equote__review-email-basic-content {
      .basic-content__title {
        font-size: 48px;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        font-family: $font-kapra;
      }
      .basic-content__body {
        font-family: $font-proxima-nova;
      }
    }
    .equote__review-email-agent-banner {
      font-family: $font-proxima-nova;
      &-title {
        font-family: $font-proxima-nova;
        color: $royal-blue;
      }
      &-subtitle {
        font-family: $font-proxima-nova;
      }
    }
    .equote__review-email-agent-data-col {
      .title {
        font-family: $font-proxima-nova;
      }
      .details {
        font-family: $font-proxima-nova;
      }
    }
  }

  &--content-container {
    padding: 24px 39px 34px;
  }

  //   ========================================================================
  //   HEADER
  //   ========================================================================
  .equote__review-email-header {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include MQ(md) {
      flex-direction: row;
    }

    .equote__review-email-social {
      margin: 0;

      p {
        width: auto;
      }

      img {
        height: 28px;
        width: 28px;
      }
    }
  }

  .equote__review-email-header-brand {
    max-width: 115px;
    max-height: 55px;

    img {
      width: 100%;
      max-height: 100%;
      margin-bottom: 0;
    }
  }

  //   ========================================================================
  //   SOCIAL
  //   ========================================================================
  .equote__review-email-social {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    row-gap: 25px;
    justify-content: center;
    h4 {
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 700;
    }

    a {
      padding: 0 $col-gutter - 18;
    }
  }

  //   ========================================================================
  //   HERO
  //   ========================================================================
  .equote__review-email-hero {
    min-height: 280px;
    position: relative;

    @media screen and ($bp-min-sm) {
      min-height: 350px;
    }
  }

  .equote__review-email-hero-overlay {
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: $col-gutter + 5;
    text-align: center;
    text-transform: uppercase;

    .overlay-title {
      color: $white;
      margin: 0 0 $col-gutter - 5 0;
    }
  }

  //   ========================================================================
  //   BASIC CONTENT
  //   ========================================================================
  .equote__review-email-basic-content {
    padding: 27px 0 0;
    text-align: center;

    @include MQ(md) {
      padding: 27px 0 0;
    }

    .basic-content__title {
      font-size: $font-size-base + 1;
      line-height: 1.5;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 8px;
    }

    .basic-content__body {
      font-size: $font-size-base - 2;
      line-height: 30px;
      color: $white;
    }
  }

  //   ========================================================================
  //   AGENT BANNER
  //   ========================================================================
  .equote__review-email-agent-banner {
    display: flex;
    flex-wrap: wrap;
    padding: 15px $col-gutter + 10 $col-gutter + 10 $col-gutter + 10;
    align-items: center;
    justify-content: center;

    @include OnIE() {
      display: block;
    }

    @include MQ(md) {
      padding: 0 $col-gutter + 10 $col-gutter + 10 $col-gutter + 10;
      flex-wrap: nowrap;
    }
    &-title {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      flex: 100%;
      margin: 0;
      text-align: center;
      margin-bottom: 8px;
    }

    &-subtitle {
      text-align: center;
      font-size: 16px;
      line-height: normal;
      color: #4a4a4a;
      padding: 0 20px;
    }
  }

  .equote__review-email-agent-image {
    flex: 1;
    max-width: 180px;
    min-width: 90px;

    img {
      border: 1px solid $medium-grey;
      width: 100%;
    }
  }

  .equote__review-email-agent-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 0;
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  .equote__review-email-agent-data-col {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex: 100%;
    width: 100%;
    margin-bottom: 10px;
    p {
      line-height: normal;
      font-size: 16px;
    }

    .title {
      padding: 0 $col-gutter - 15 0 0;
    }

    .details {
      font-weight: 600;
    }
  }

  .equote__review-email-social {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    p {
      font-style: italic;
      margin: 0 0 $col-gutter - 15 0;
      text-align: center;
      width: 100%;
    }

    &-icons {
      display: flex;

      a,
      & > img {
        width: 30px;
        margin: 0 4px;
      }
    }
  }

  .equote__review-email-terms {
    font-size: $font-size-base - 6;
    padding: 0 $col-gutter;
    text-align: center;

    @media screen and ($bp-min-lg) {
      padding: 0 $col-gutter * 5 $col-gutter + 10 $col-gutter * 5;
    }
  }
}

.equote__review-construct-email-header {
  display: flex;
  align-items: center;
  margin: 0 0 $col-gutter + 13 0;

  .equote__review-construct-email-header-img {
    width: 64px;
    height: 64px;
    border: 1px solid $grey;
    border-radius: 64px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $col-gutter;
  }

  h6 {
    color: $medium-dark-grey;
    font-size: $font-size-base + 9;
    font-weight: 400;
  }
}
.equote__review-construct-email-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  p {
    color: $white;
    font-size: 14px;
  }
  &-logo {
    display: flex;
    gap: 9px;
    flex-direction: column;
    @include MQ(md) {
      flex-direction: row;
    }
    justify-content: center;
    align-items: center;
    img {
      max-height: 32px;
    }
  }
  &.brand-multi {
    background-color: $royal-blue;
    p {
      font-family: $font-proxima-nova;
    }
  }
  &.brand-c {
    background-color: $celebrity-grey;
    p {
      font-family: $font-roboto;
    }
  }
  &.brand-r {
    background-color: $royal-blue;
    p {
      font-family: $font-proxima-nova;
    }
  }
  .equote__review-construct-email-copyright {
    text-align: center;
  }
}
